import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AboutSchemeImage from '../../Assets/image/Shailendra.jpg';
import { Link } from 'react-router-dom';

function HighEducation() {
    return (
        <div className="mt-5">
            <Container>
                <Row>
                    <Col md={7}>
                        <div className="text-justify mt-4" style={{ textAlign: 'justify' }} >
                            <h2 style={{ fontWeight: '500' }}>
                                <strong>Message from the Director</strong>
                            </h2>

                            <div className='feature-bottom-1 mb-3'></div>
                            {/* <p>Dear Students, Parents, and Esteemed Colleagues,</p> */}
                            <div style={{ textAlign: 'justify' }} >
                                <p>
                                    Dear Students, Parents, and Well-Wishers,
                                    I am filled with great enthusiasm as I share with you the inspiration behind our latest endeavor – the Swami Vivekananda Yuva Suraksha Yojana. It brings me immense joy to be a part of the vision set forth by the Hon. Minister of Higher & Technical Education, a vision that aims to uplift the lives of our students and their families across the state of Maharashtra.
                                    <br /><br />
                                    The Swami Vivekananda Yuva Suraksha Yojana is not just a program; it is a manifestation of empathy and a commitment to ensuring that every student has the opportunity to chase their dreams unencumbered by unforeseen challenges. Named after the illustrious Swami Vivekananda, this initiative is a beacon of hope, echoing the principles of compassion, resilience, and empowerment.
                                </p>
                                <p>
                                    As the Director of the Directorate of Higher Education, my motivation stems from witnessing the transformative power of education and the profound impact it has on individuals and communities. It is our collective responsibility to disseminate the essence of Swami Vivekananda Yuva Suraksha Yojana to every corner of Maharashtra, ensuring that no deserving student is left unaware of the support available to them.
                                    <br /><br />
                                    We aim to create a movement, one that spreads the light of this yojana far and wide. Through a mass dissemination campaign, we aspire to reach every educational institution, every student, and every family, emphasizing the significance of this protective shield. We believe that by uniting in this endeavor, we can build a stronger, more resilient educational ecosystem.
                                </p>
                                <p>I encourage educators, administrators, students, and parents to join hands with us in this mission. Let us make Swami Vivekananda Yuva Suraksha Yojana a household name, symbolizing security, support, and the unwavering belief in the potential of our youth.
                                    Let's light up the future for our students.</p>
                            </div>
                            {/* <p>
                This initiative is not just a scheme; it is an investment in the future leaders, thinkers, and innovators of our nation. Together, let's amplify the reach of Swami Vivekananda Yuva Suraksha Yojana and pave the way for a generation that thrives on the strength of its education and the unwavering support of its community.
              </p> */}
                            {/* <p>
                For any inquiries or to contribute to this cause, please do not hesitate to reach out to us at [contact details]. Empowering our youth is not just a responsibility; it is our collective privilege.
              </p> */}
                            {/* <p className="mt-0 mb-0">With Sincere Regards,<br />Vikas Rastogi<br />Hon. Principal Secretary<br />Department of Higher & Technical Education<br />Govt. of Maharashtra</p> */}

                        </div>
                    </Col>
                    <Col md={5}>
                        <div className='d-flex justify-content-center align-items-center mt-5'> <img src={AboutSchemeImage} alt="About Scheme" className="img-swami" />

                        </div>
                        <div className='ms-5 mt-5'>
                            <h4><strong>Dr. Shailendra Deolankar</strong></h4>
                            <h4><strong>Incharge Director</strong></h4>
                            <h4><strong>Higher Education</strong></h4>
                        </div>

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default HighEducation;
