import React, { useEffect, useState } from 'react';
import { useFormData } from '../../Contexts/FormDataContext';
import policyImage from '../../Assets/image/Comapny.jpg';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { useNavigate } from 'react-router-dom';

function InsuranceCompany() {
    const { formData, updateFormData, dataUpdated, resetDataUpdated } = useFormData();
    const [selectedCompany, setSelectedCompany] = useState('');
    const navigate = useNavigate();
    const [planName, setPlanName] = useState('');
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

    useEffect(() => {
        // Enable the "Next" button if a planName is selected
        setIsNextButtonDisabled(!selectedCompany);
    }, [selectedCompany]);

    useEffect(() => {
        setPlanName(formData.planName);
    }, []);

    const handleNextClick = (e) => {
        e.preventDefault();
        updateFormData({ selectedCompany });
    };

    useEffect(() => {
        // Check if data has been updated
        if (dataUpdated) {
            // Perform additional logic if needed
            console.log('Data has been updated in InsuranceCompany component!');
            console.log(formData);
            // Reset the flag
            resetDataUpdated();
            navigate('/enrollment-type');
        }
    }, [dataUpdated, resetDataUpdated]);

    useEffect(() => {
        // Add an event listener for beforeunload
        const handleBeforeUnload = (event) => {
            // Cancel the event to prevent the default behavior
            event.preventDefault();
            // Standard message, works on most browsers
            event.returnValue = '';
        };

        // Attach the event listener
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [selectedCompany]);

    return (
        <div className='container mt-5'>
            <div className='d-flex justify-content-center mt-5'>
                <div className='main-div row justify-content-md-center'>
                    <div className='col-12 heading-box mt-5 text-center'>
                        <h4 className='heading-box-title mt-2'>Insurance Company Selection</h4>
                    </div>
                    <div className='row justify-content-md-center'>
                        <Col xs={12} md={4} className='enrollment-box-first'>
                            <Image src={policyImage} className='image-enrollment' />
                        </Col>
                        <Col xs={12} md={6}>
                            <Form>
                                <Form.Select
                                    aria-label="Default select example"
                                    className='upload-details-form'
                                    onChange={(event) => setSelectedCompany(event.target.value)}
                                    value={selectedCompany}
                                >
                                    <option>Insurance Company Selection</option>
                                    <option value="ICICI Lombard GIC">ICICI Lombard GIC</option>
                                    {planName === 'Personal Accident Only' && (
                                        <option value="National Insurance Co.">National Insurance Co.</option>
                                    )}
                                </Form.Select>
                                <div className='mt-5'>
                                    <button
                                        type='button'
                                        className='btn btn-warning'
                                        disabled={isNextButtonDisabled}
                                        onClick={handleNextClick}
                                    >
                                        Next
                                    </button>
                                </div>
                            </Form>
                        </Col>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InsuranceCompany;
