import React from 'react'
import MinisterPhoto from '../ParentDepartment/MinisterPhoto'
import Table from 'react-bootstrap/Table';
import { FaCheck } from "react-icons/fa6";

const index = () => {
    return (
        <>
            <MinisterPhoto heading="Content Review Policy" />
            <div className='container mt-5'>
                <div>
                    <p>The contents are review as when required by the department/Division and the revised content is published through CMS as per COMAP.</p>
                </div>
                <div className='mt-5'>
                    <Table responsive bordered>
                        <thead className='table-header-policy'>
                            <tr className=''>
                                <th className='center-text'><h5>SN</h5></th>
                                <th className='center-text'><h5>Content Element</h5></th>
                                <th colSpan={3} className='center-text'><h5>Type of Content</h5></th>
                                <th className='center-text'><h5>Frequency of Review</h5></th>
                                <th className='center-text'><h5>Approver</h5></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th></th>
                                <th></th>
                                <th><h6>Event</h6></th>
                                <th><h6>Time</h6></th>
                                <th><h6>Policy</h6></th>
                                <th></th>
                                <th></th>

                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Home</td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td>Yearly Immediate in case of chan</td>
                                <td>-</td>

                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Directorate Of Higher Education, Government Of Maharashtra, India</td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td>Yearly Immediate in case of chan</td>
                                <td>-</td>

                            </tr>
                            <tr>
                                <td>3</td>
                                <td>GR’s</td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td>Yearly Immediate in case of chan</td>
                                <td>-</td>

                            </tr>
                            <tr>
                                <td>4</td>
                                <td>CIRCULARS</td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td>Yearly Immediate in case of chan</td>
                                <td>-</td>

                            </tr>
                            <tr>
                                <td>5</td>
                                <td>ACTIVITIES/SCHEMES</td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td>Yearly Immediate in case of chan</td>
                                <td>-</td>

                            </tr>
                            <tr>
                                <td>6</td>
                                <td>RTI</td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td>Yearly Immediate in case of chan</td>
                                <td>-</td>

                            </tr>
                            <tr>
                                <td>7</td>
                                <td>WHO’S WHO</td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td>Yearly Immediate in case of chan</td>
                                <td>-</td>

                            </tr>
                            <tr>
                                <td>8</td>
                                <td>Directorate Of Higher Education, Government Of Maharashtra, India</td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td>Yearly Immediate in case of chan</td>
                                <td>-</td>

                            </tr>
                            <tr>
                                <td>9</td>
                                <td>CONTACT US</td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td>Yearly Immediate in case of chan</td>
                                <td>-</td>

                            </tr>
                            <tr>
                                <td>10</td>
                                <td>BANNERS</td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td>Yearly Immediate in case of chan</td>
                                <td>-</td>

                            </tr>
                            <tr>
                                <td>11</td>
                                <td>RTI STATUS</td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td>Yearly Immediate in case of chan</td>
                                <td>-</td>

                            </tr>
                            <tr>
                                <td>12</td>
                                <td>TENDER/NOTICES</td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td>Yearly Immediate in case of chan</td>
                                <td>-</td>

                            </tr>
                            <tr>
                                <td>13</td>
                                <td>NEWS/ ADVERTISEMENTS</td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td>Yearly Immediate in case of chan</td>
                                <td>-</td>

                            </tr>
                            <tr>
                                <td>14</td>
                                <td>GROUP WISE CONTENTS</td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td><FaCheck /></td>
                                <td>Yearly Immediate in case of chan</td>
                                <td>-</td>

                            </tr>
                            
                            

                        </tbody>
                    </Table>
                </div>

            </div>
        </>
    )
}

export default index