import React, { useEffect } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import logo from '../Assets/image/DTELogo.png'
import logo2 from '../Assets/image/jayate.png'
const deleteCookie = (name) => {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};

function TopHeader() {
  // Function to initialize Google Translate
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: '', // Set default page language to English
        autoDisplay: true,
        includedLanguages: 'en,hi,mr',
      },
      'google_translate_element'
    );
  };

  useEffect(() => {
    // Clear the Google Translate language cookies on page load
    deleteCookie('googtrans'); // Main cookie storing language info
    deleteCookie('googtrans/en'); // Clear any specific variant of this cookie

    // Append Google Translate script
    const addScript = document.createElement('script');
    addScript.async = true;
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);

    // Set global function for TranslateElement
    window.googleTranslateElementInit = googleTranslateElementInit;

    return () => {
      // Clear the language setting when the tab is closed or reloaded
      window.addEventListener('beforeunload', () => {
        localStorage.removeItem('googtrans');
        localStorage.removeItem('googtrans/en');
      });
    };
  }, []);
  return (
    <Navbar bg="light" data-bs-theme="light">
      <Container>
        <Navbar.Brand href="/" className='ms-2'>
          <img src='/LOGO-01.svg' className='navbarImageSize' alt='' />
          <div className='ms-3'>
            <p style={{ margin: 0, fontSize: '18px' }} className='topHeader-text'>Government of Maharashtra</p>
            <p style={{ margin: 0, fontSize: '18px' }} className='topHeader-text'>Department of Higher and Technical Education</p>
            <h3 style={{ margin: 0, fontSize: '26px' }} className='media-text'><strong>Swami Vivekananda Yuva Suraksha Yojana</strong></h3>
            {/* <span style={{ margin: 0 ,fontSize:'13px' }}>Maharashtra State, India</span> */}
          </div>
        </Navbar.Brand>
        <Nav className='ms-auto'>
          {/* <Nav.Link href="/" active className='me-4  active-link'> */}
          {/* <img src={logo2} className='media-nav-logo'></img> */}
          <div id="google_translate_element" className='google-translate'></div>
          {/* </Nav.Link> */}
        </Nav>
      </Container>
    </Navbar>
  )
}

export default TopHeader