
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

function Enquiry() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className='container d-flex justify-content-end mt-5'>
                <Button variant="warning" className='p-3' onClick={handleShow}>
                    Feedback
                </Button>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Enquiry Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control placeholder="First name" />
                                </Form.Group >
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control placeholder="Last name" />
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control placeholder="Phone Number" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="name@example.com"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Job Title</Form.Label>
                                    <Form.Control placeholder="Job Title" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Organisation Name </Form.Label>
                                    <Form.Control
                                        placeholder="Organisation Name"
                                    />
                                </Form.Group>
                            </Col>
                        </Row> */}
                        {/* <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control placeholder="City" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control
                                        placeholder="Country"
                                    />
                                </Form.Group>
                            </Col>
                        </Row> */}
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Type Your Enquiry Here</Form.Label>
                            <Form.Control as="textarea" rows={3} />
                        </Form.Group>

                        {/* <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Upload Document</Form.Label>
                            <Form.Control type="file" />
                        </Form.Group> */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {/* <button className='popup-button-close'  onClick={handleClose}>
                        Close
                    </button> */}
                    <button className='popup-button-submit btn btn-warning' onClick={handleClose}>
                        Submit
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Enquiry;