import React from 'react'
import minister from '../../Assets/image/DTELogo.png'

function Director() {
    return (
        <>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-4 mt-5'>
                        <div className='minister-image d-flex justify-content-center align-items-center'>
                            <img src={minister} className='img-info-1' style={{height: "350px"}} />
                        </div>
                        <div className='information-title mt-2'>
                            {/* <h5 style={{ fontWeight: '400'  }}><strong>Shri. Chandrakant Patil - Hon. Cabinet Minister </strong></h5> */}
                            {/* <h5 style={{ fontWeight: '400'  }}><strong> Higher & Technical Education, Govt. of Maharashtra</strong></h5> */}
                        </div>
                    </div>
                    <div className='col-md-8 mt-3'>
                        <div className='minister-information'>
                            <div className='information-title'>
                                {/* <h2 style={{ fontWeight: '500' }}>Message from Hon’ble Minister</h2> */}
                                <h2 style={{ fontWeight: '500' }}><strong>About Directorate</strong></h2>
                                <div className='feature-bottom-1'></div>
                            </div>
                            <div className='information-para mt-3 '>
                                <div style={{ textAlign: 'justify' }}>
                                    <p>
                                    The Directorate of Higher Education was entrusted with the responsibility of the Management of Non ‐ agricultural Universities and its Degree Colleges of higher education in the faculties of Arts, Science, Commerce, law, Education, and Non ‐ AICTE in the State. In the year 1969, the State Government had introduced 10+2+3 systems of education and started the number of Senior Colleges in the State to impart Higher Education. The number of these colleges increased immensely after the formation of the Directorate of Higher Education on 31‐10‐ 1984. Consequently, the administrative responsibilities of the Director of Higher Education have enormously increased and the centralized management of all the colleges had become very difficult from the State level Directorate located at Pune. Thus the need was felt to decentralize the organization which has resulted in the creation of 10 regional offices in the State to oversee the functioning of colleges in the respective regions. The 10 offices of Regional Joint Directors are located at Mumbai, Panvel, Pune, Kolhapur, Solapur, Aurangabad, Nanded, Jalgaon, Amravati, and Nagpur.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Director