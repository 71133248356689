import React from 'react'
import Navigation from './Navigation'
import TopHeader from '../TopHeader'

function Navbar() {
  return (
    <>
    <TopHeader/>
    <Navigation/>
    </>
  )
}

export default Navbar