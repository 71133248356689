import React, { useState } from 'react';
import { TiBusinessCard } from "react-icons/ti";
import { MdUploadFile } from "react-icons/md";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FaUpload } from "react-icons/fa";
import { Link } from 'react-router-dom';

function InformationDetails() {
    const [selectedFileName, setSelectedFileName] = useState('');

    const handleFileUploadClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        // Do something with the selected file, like uploading or processing
        console.log('Selected File:', selectedFile);

        // Update the state with the selected file name
        setSelectedFileName(selectedFile.name);
    };

    return (
        <>
            <div className='container'>
                <div className='d-flex justify-content-center mt-5'>
                    <div className='main-div row justify-content-md-center'>
                        <div className='col-12 heading-box mt-5 text-center'>
                            <h4 className='heading-box-title mt-2'>Information Details</h4>
                        </div>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-5  d-flex justify-content-around'>
                                    <div className='upload-box '>
                                        <div className='d-flex flex-wrap justify-content-center p-3'>
                                            <h4>Upload PAN GST KYC</h4>
                                            <TiBusinessCard style={{ fontSize: "100px" }} className='uploads-icons' />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>

                                    <div>
                                        <InputGroup className="mb-3 upload-details-input ">
                                            <Form.Control
                                                className='upload-details-form'
                                                placeholder="Enter Pan Number"
                                            />
                                            <input
                                                type="file"
                                                id="fileInput"
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange}
                                            />
                                            <Button
                                                variant="outline-secondary"
                                                id="button-addon2"
                                                onClick={handleFileUploadClick}
                                            >
                                                <MdUploadFile style={{ fontSize: '24px' }} /> Upload
                                            </Button>
                                        </InputGroup>
                                        {selectedFileName && <p style={{color:'grey'}}><strong>Selected File: {selectedFileName}</strong></p>}
                                    </div>

                                    <div>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

                                            <Form.Control className='upload-details-form' type="text" placeholder="Enter Gst Number" />
                                        </Form.Group>
                                    </div>

                                    <div>
                                        {/* Other form elements */}
                                    </div>
                                    <div className='d-flex justify-content-around'>
                                    <Link to='/uploadData'> <button className=' mt-3 btn btn-warning'>Back</button></Link>
                                        <Link to='/personalInfo'> <button className=' mt-3 btn btn-warning'>Next</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InformationDetails;
