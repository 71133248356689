import React from 'react'
import MinisterPhoto from '../ParentDepartment/MinisterPhoto'

const index = () => {
    return (
        <>
            <MinisterPhoto heading="Website Monitoring Plan" />
            <div className='container mt-5'>
                <p>
                    Website Monitoring Policy in place and the website is monitored periodically to address and fix the quality and compatibility issues around the following parameters: Performance: Site download time is optimized for a variety of network connections as well as devices. All important pages of the website are tested for this. Functionality: All modules of the website are tested for their functionality. The interactive components of the site such as feedback forms are working smoothly. Broken Links: The website is thoroughly reviewed to rule out the presence of any broken links or errors. Traffic Analysis: The site traffic is monitored to analyze the usage patterns as well as visitors. Feedback: Feedback from the visitors is the best way to judge a website’s performance and make the necessary improvements. A proper mechanism for feedback is in place to carry out the changes and enhancements as suggested by the visitors. The Hosting Service Provider possesses state-of-the-art multi-tier security infrastructure as well as devices such as firewall and intrusion prevention systems.
                </p>
            </div>
        </>
    )
}

export default index