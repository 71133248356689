import React from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { FaFileDownload } from 'react-icons/fa';
import Comaparison from '../../Assets/Excel/Swami Vivekanand Yuva Suraksha Yojana Comparision National.pdf'
import Claim from '../../Assets/Excel/National Claim Doc.pdf'



function NationalDoc() {
  const downloadFile = (fileName) => {
    // Implement your file download logic here
    window.open(fileName)
  };

  return (
    <div className='container mt-5'>
      <Row xs={1} md={3} className='g-4'>
        {/* Using xs={1} to make it a single column on small screens, and md={3} to have 3 columns on medium and larger screens */}
        <Col>
          <Card className='document' border='primary' style={{ boxShadow: '0 4px 8px #ec9c79' }}>
            <Card.Body>
              <FaFileDownload size={30} className='mb-3' />
              <Card.Title>GPA-Claim-Form</Card.Title>
              <Button
                variant='warning'
                onClick={() => downloadFile(Claim)}
              >
                Download
              </Button>
            </Card.Body>
          </Card>
        </Col>
        {/* <Col>
          <Card className='document' border='primary' style={{ boxShadow: '0 4px 8px #ec9c79' }}>
            <Card.Body>
              <FaFileDownload size={30} className='mb-3' />
              <Card.Title>Comparison</Card.Title>
              <Button
                variant='warning'
                onClick={() => downloadFile(Comaparison)}
              >
                Download
              </Button>
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
    </div>
  );
}

export default NationalDoc;
