import React from 'react'
import FinalDasborad from './FinalDasborad'

function Review() {
  return (
    <>
    <FinalDasborad/>
    </>
  )
}

export default Review
