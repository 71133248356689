
import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";
import Insurance from '../Components/Insurance';
import UploadData from '../Components/UploadData';
import PanGstKyc from '../Components/Pan-Gst-Kyc';
import Login from '../Components/Login';
import SignUp from '../Components/SignUp';
import Home from '../Components/Home';
import Product from '../Components/Product';
import EnrollmentType from '../Components/EnrollmentType';
import Review from '../Components/Review';
import Contacts from '../Components/Contacts';
import ForgotPassword from '../Components/ForgotPassword';
import About from '../Components/About';
import Press from '../Components/Press';

import ImplementingBody from '../Components/ImplementingBody';

import Disclaimer from '../Components/Disclaimer';
import ICICI from '../Components/ICICI';
import National from '../Components/National';
import GR from '../Components/GR'
import Leaders from '../Components/Leaders';
import NodalAgency from '../Components/Nodal-Agency';
import CoverageBenefits from '../Components/PersonalAccidents';
import NationalBenefits from '../Components/MediclaimBenefits';
import TermsAndConditions from '../Components/TermsAndConditons';
import AccessibilityStatement from '../Components/AccessibilityStatement';
import WebsitePolicies from '../Components/WebsitePolicies';
import DisclaimerFooter from '../Components/DisclaimerFooter';
import CopyrightPolicy from '../Components/CopyrightPolicy';
import PrivacyPolicy from '../Components/PrivacyPolicy';
import HyperlinkPolicy from '../Components/HyperlinkPolicy';
import ContentArchivalPolicy from '../Components/ContentArchivalPolicy';
import WebsiteMonitoringPlan from '../Components/WebsiteMonitoringPlan';
import ContentContribution from '../Components/ContentContribution';
import ContentReviewPolicy from '../Components/ContentReviewPolicy';
import ContingencyManagementPlan from '../Components/ContingencyManagementPlan';
import SecurityPolicy from '../Components/SecurityPolicy';
import Dashboard from '../Dashboard/main';
import Navbar from '../Constants/Navbar';
import Footer from '../Components/Footer/Footer';
import AboutTheScheme from '../Components/ParentDepartment';



function PrivateRoute({ element, path }) {
    const navigate = useNavigate()
    const token = sessionStorage.getItem('token');

    useEffect(() => {
        if (!token) {
            // Redirect to the login page if the token is not present
            navigate('/login');
        }
    }, [token]);

    return token ? element : null;
}

function Router() {
    return (
        <>
            <Navbar />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route
                    path="/enrollment"
                    element={<PrivateRoute path="/enrollment" element={<Product />} />}
                />
                {/* <Route path="/insuranceCompany" element={<Insurance />} /> */}
                <Route
                    path="/uploadData"
                    element={<PrivateRoute path="/uploadData" element={<UploadData />} />}
                />
                <Route path='/personalInfo' element={<PanGstKyc />} />
                {/* <Route path='/login' element={<Login />} /> */}
                <Route
                    path="/insurance-company"
                    element={<PrivateRoute path="/insurance-company" element={<Insurance />} />}
                />
                <Route
                    path="/enrollment-type"
                    element={<PrivateRoute path="/enrollment-type" element={<EnrollmentType />} />}
                />
                {/* <Route path='/sign-up' element={<SignUp />} /> */}
                <Route path='/review' element={<Review />} />
                <Route path='/contact' element={<Contacts />} />
                {/* <Route path='/about' element={<About/>}/> */}
                <Route path='/forgotPassword' element={<ForgotPassword />} />
                <Route path='/press' element={<Press />} />
                <Route path='/about-scheme' element={<AboutTheScheme />} />
                <Route path='/implementing-body' element={<ImplementingBody />} />
                <Route path='/personal-accidents' element={<CoverageBenefits />} />
                <Route path='/mediclaim-coverage' element={<NationalBenefits />} />
                <Route path='/icici-document' element={<ICICI />} />
                <Route path='/national-document' element={<National />} />
                <Route path='/disclaimer' element={<Disclaimer />} />
                <Route path='/gr' element={<GR />} />
                {/* <Route path='/minster-info' element={<Leaders />} /> */}
                <Route path='/nodalAgency' element={<NodalAgency />} />
                <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                <Route path='/accessibility-statement' element={<AccessibilityStatement />} />
                <Route path='/website-policies' element={<WebsitePolicies />} />
                <Route path='/disclaimer-footer' element={<DisclaimerFooter />} />
                <Route path='/copyright-policy' element={<CopyrightPolicy />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/hyperlink-policy' element={<HyperlinkPolicy />} />
                <Route path='/content-archival-policy' element={<ContentArchivalPolicy />} />
                <Route path='/website-monitoring-plan' element={<WebsiteMonitoringPlan />} />
                <Route path='/content-contribution' element={<ContentContribution />} />
                <Route path='/content-review-policy' element={<ContentReviewPolicy />} />
                <Route path='/contingency-management-plan' element={<ContingencyManagementPlan />} />
                <Route path='/security-policy' element={<SecurityPolicy />} />
                {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            </Routes >
            <Footer />
            

        </>
    )
}

export default Router