import React from 'react'
import PolicyBenefits from './PolicyBenefits'
import MinisterPhoto from '../ParentDepartment/MinisterPhoto'
import AboutScheme from '../Home/AboutScheme'
import FirstComponent from './FirstComponent'

function CoverageBenefits() {
  return (
    <>
    <FirstComponent/>
    <MinisterPhoto heading="Coverage Benefits" />
    <PolicyBenefits/>
    </>
  )
}

export default CoverageBenefits