import React from 'react'
import CompanySection from './CompanySection'


import PolicyBenefits from '../PersonalAccidents/PolicyBenefits'

import Footer from '../Footer/Footer'
import Navbar from '../../Constants/Navbar'
import YojanaLetter from '../ParentDepartment/YojanaLetter'


function About() {
    return (
        <>
            {/* <CompanySection /> */}
            <Navbar />
            <YojanaLetter />
            <PolicyBenefits />
            <Footer />
        </>
    )
}

export default About