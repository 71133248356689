import React from 'react'
import minister from '../../Assets/image/chandrakant-dada-patil.jpg'

function Information() {
    return (
        <>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-5 mt-5'>
                        <div className='minister-image d-flex justify-content-center align-items-center'>
                            <img src={minister} className='img-info' />
                        </div>
                        <div className='information-title mt-3'>
                            <h5 style={{ fontWeight: '400'  }}><strong>Shri. Chandrakant Patil</strong></h5>
                            <h5 style={{ fontWeight: '400'  }}><strong>Hon’ble  Minister</strong></h5>
                            <h5 style={{ fontWeight: '400'  }}><strong>Higher and Technical Education</strong></h5>
                        </div>
                    </div>
                    <div className='col-md-7 mt-3'>
                        <div className='minister-information'>
                            <div className='information-title'>
                                {/* <h2 style={{ fontWeight: '500' }}>Message from Hon’ble Minister</h2> */}
                                <h2 style={{ fontWeight: '500' }}><strong>Message from Hon’ble Minister</strong></h2>
                                <div className='feature-bottom-1'></div>
                            </div>
                            <div className='information-para mt-3 '>
                                <div style={{ textAlign: 'justify' }}>
                                    <p>
                                        "Unveiling the Swami Vivekananda Yuva Suraksha Yojana - A Shield for Dreams. Unveiling a heartfelt initiative by the Government of Maharashtra, led by our passionate Minister of Higher & Technical Education. We are launching the Swami Vivekananda Yuva Suraksha Yojana, a comprehensive insurance scheme crafted with a vision to protect the dreams of our young minds pursuing higher and technical education.
                                        <br /><br />
                                        Named after the iconic Swami Vivekananda, a beacon of youth inspiration and a catalyst for social change, this scheme is more than just a safety net; it's a promise to stand by our students when life throws unexpected challenges their way.
                                    </p>
                                    <p>
                                        We all know that life can be unpredictable, and our students often find themselves at a crossroads when faced with the sudden loss or disability of a parent or guardian. It's during these testing times that the Swami Vivekananda Yuva Suraksha Yojana steps in, offering a lifeline to prevent higher dropout rates and turmoil within families, ensuring that our students can continue their educational journey with resilience and determination.
                                        <br /><br />
                                        The spirit of Swami Vivekananda, an advocate for the holistic development of young minds, resonates through this scheme. It's about nurturing not just academic growth but also providing a compassionate shield that understands the emotional and financial strains our students may face."
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Information