import React, { createContext, useContext, useState } from 'react';

const FormDataContext = createContext();

export const FormDataProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  const [dataUpdated, setDataUpdated] = useState(false);

  const updateFormData = (data) => {
    setFormData((prevData) => ({ ...prevData, ...data }));
    setDataUpdated(true);
  };

  const resetDataUpdated = () => {
    setDataUpdated(false);
  };

  return (
    <FormDataContext.Provider value={{ formData, updateFormData, dataUpdated, resetDataUpdated }}>
      {children}
    </FormDataContext.Provider>
  );
};

export const useFormData = () => {
  return useContext(FormDataContext);
};
