// import React from 'react';
// import { Card } from 'react-bootstrap';

// function History() {
//   return (
//     <div className='container mt-5'>
//       <Card className="custom-card" style={{ backgroundColor: '#FFE4B5' }}>
//         <div className='container' style={{ padding: '30px' }}>
//           <h2 style={{ fontWeight: '500' }}><strong>About the Department</strong></h2>
//           <div className='feature-bottom-1'></div>
//           <div className='information-para mt-3 '>
//             <div style={{ textAlign: 'justify' }}>
//               <p>
//                 The Department of Higher and Technical Education, under the Government of Maharashtra, is responsible for formulating and implementing policies pertaining to higher and technical education. Playing a pivotal role in the comprehensive development of educational institutions, the department devises progressive policies that cultivate a conducive learning environment, driving educational growth and success through the state.
//               </p>
//             </div>
//           </div>
//         </div>
//       </Card>
//     </div>
//   );
// }

// export default History;



import React from 'react'
import minister from '../../Assets/image/DTELogo.png'

function History() {
  return (
    <>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-4 mt-3'>
            <div className='minister-image-1 d-flex justify-content-center align-items-center'>
              <img src={minister} className='img-info-1' style={{ height: "200px" }} />
            </div>
            <div className='information-title mt-2'>
              {/* <h5 style={{ fontWeight: '400'  }}><strong>Shri. Chandrakant Patil - Hon. Cabinet Minister </strong></h5> */}
              {/* <h5 style={{ fontWeight: '400'  }}><strong> Higher & Technical Education, Govt. of Maharashtra</strong></h5> */}
            </div>
          </div>
          <div className='col-md-8 mt-3'>
            <div className='minister-information'>
              <div className='information-title'>
                {/* <h2 style={{ fontWeight: '500' }}>Message from Hon’ble Minister</h2> */}
                <h2 style={{ fontWeight: '500' }}><strong>About the Department</strong></h2>
                <div className='feature-bottom-1'></div>
              </div>
              <div className='information-para mt-3 '>
                <div style={{ textAlign: 'justify' }}>
                  <p>

                    The Department of Higher and Technical Education, under the Government of Maharashtra, is responsible for formulating and implementing policies pertaining to higher and technical education. Playing a pivotal role in the comprehensive development of educational institutions, the department devises progressive policies that cultivate a conducive learning environment, driving educational growth and success through the state

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default History