import React from 'react'

function Disclaimer() {
    return (
        <>
            <div className="container mt-5">
                <h2 className="mb-4"><strong>Terms and Conditions</strong></h2>
                <p>This document contains the terms and conditions governing your enrollment in the Swami Vivekananda Yuva Suraksha Yojana ("the Scheme"). The Scheme is facilitated by the Department of Higher & Technical Education, Government of Maharashtra, as per the Government Resolution (G.R.) issued by the Government of Maharashtra available on this website <a href="https://svyuvasuraksha.org" target="_blank" rel="noopener noreferrer">svyuvasuraksha.org</a>.</p>

                <p>By enrolling in the Scheme through the website <a href="https://svyuvasuraksha.org" target="_blank" rel="noopener noreferrer">svyuvasuraksha.org</a>, you agree to abide by the following terms and conditions:</p>

                <ol>
                    <li>
                        <h5><strong>Eligibility Criteria:</strong></h5>
                        <ul>
                            <li>You must fulfill the eligibility criteria as laid out in the Government Resolution.</li>
                            <li>Any false representation of eligibility may result in disqualification from the Scheme.</li>
                        </ul>
                    </li>

                    <li>
                        <h5><strong>Application Process:</strong></h5>
                        <ul>
                            <li>The information provided during the enrollment process must be accurate and complete.</li>
                            <li>The Insurance Company reserves the right to verify the information provided.</li>
                        </ul>
                    </li>

                    <li>
                        <h5><strong>Data Privacy:</strong></h5>
                        <ul>
                            <li>Your personal information will be collected, processed, and stored in accordance with applicable data protection laws.</li>
                            <li>The Insurance Company & all other allied entities, organisations, bodies involved in the implementation of Swami Vivekananda Yuva Suraksha Yojana will take necessary measures to safeguard your data.</li>
                        </ul>
                    </li>

                    <li>
                        <h5><strong>Scheme Benefits:</strong></h5>
                        <ul>
                            <li>The benefits provided under the Scheme are subject to the terms specified in the Government Resolution.</li>
                            <li>The Government of Maharashtra reserves the right to modify the benefits and terms at its discretion.</li>
                        </ul>
                    </li>

                    <li>
                        <h5><strong>Term and Termination:</strong></h5>
                        <ul>
                            <li>Your enrollment in the Scheme is valid for the specified term.</li>
                            <li>The Insurance Company reserves the right to terminate your enrollment for non-compliance with the terms and conditions.</li>
                        </ul>
                    </li>

                    <li>
                        <h5><strong>Governing Law:</strong></h5>
                        <ul>
                            <li>This agreement is governed by the laws of the State of Maharashtra.</li>
                            <li>Any disputes arising out of or in connection with this agreement shall be subject to the exclusive jurisdiction of the courts of Maharashtra.</li>
                        </ul>
                    </li>
                </ol>

                {/* <p>By clicking the "I Accept" button, you confirm that you have read, understood, and agree to comply with the terms and conditions outlined above.</p> */}
            </div>
        </>
    )
}

export default Disclaimer