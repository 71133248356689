import React from 'react'
import GRDownload from './GRDownload'
import MinisterPhoto from '../ParentDepartment/MinisterPhoto'

const index = () => {
  return (
    <div>
      <MinisterPhoto heading='Government Resolutions'/>
      <GRDownload/>
      </div>
  )
}

export default index