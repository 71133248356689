import React from 'react'
import MinisterPhoto from '../ParentDepartment/MinisterPhoto'
import Table from 'react-bootstrap/Table';

const index = () => {
    return (
        <>
            <MinisterPhoto heading="Content Archival Policy" />
            <div className='container mt-5'>
                <div>
                    <p>
                        The latest news and spotlight items will be archived automatically after entering the end date from the date of their publishing. All news/ tenders/ notices /announcements /press releases are archived after the given expiry date. The web manager entered the expiry date while entering the details with due approval from the respective department.
                    </p>
                    <p>
                        Any item from Archives will be taken off only after the approval of the web information manager or concern authority (written communication through email). Such items, though not visible in the archive, remain in the CMS database and can be accessed from Database if required
                    </p>
                </div>
                <div className='mt-5'>
                    <Table responsive bordered> 
                        <thead className='table-header-policy'>
                            <tr className=''>
                           
                                <th><h4>SN</h4></th>
                                <th><h4>Content Element</h4></th>
                                <th><h4>Entry Policy</h4></th>
                                <th><h4>Exit Policy</h4></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Home</td>
                                <td>Whenever Departments/ Ministries are merged /changed.</td>
                                <td>Perpetual (10 years) since the dating of entry into archival.</td>

                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Directorate of Forensic Science Laboratories, Government of Maharashtra</td>
                                <td>Completion of its validity period.</td>
                                <td>Five (05) years since the date of discontinuation.</td>

                            </tr>
                            <tr>
                                <td>3</td>
                                <td>GR’s</td>
                                <td>Completion of its validity period.</td>
                                <td>Perpetual (10 years) since the dating of entry into archival.</td>

                            </tr>
                            <tr>
                                <td>4</td>
                                <td>CIRCULARS</td>
                                <td>Completion of its validity period.</td>
                                <td>Perpetual (10 years) since the dating of entry into archival.</td>

                            </tr>
                            <tr>
                                <td>5</td>
                                <td>ACTIVITIES/SCHEMES</td>
                                <td>Completion of its validity period.</td>
                                <td>Five (05) years since the date of discontinuation.</td>

                            </tr>
                            <tr>
                                <td>6</td>
                                <td>RTI</td>
                                <td>As soon as it loses relevance</td>
                                <td>Perpetual (10 years) since date of entry into archival.</td>

                            </tr>
                            <tr>
                                <td>7</td>
                                <td>WHO’S WHO</td>
                                <td>Whenever Departments/ Ministries are merged /changed.</td>
                                <td>Perpetual (10 years) since the dating of entry into archival.</td>

                            </tr>
                            <tr>
                                <td>8</td>
                                <td>CONTACT US</td>
                                <td>Not required</td>
                                <td>Not Applicable</td>

                            </tr>
                            <tr>
                                <td>9</td>
                                <td>Directorate of Forensic Science Laboratories, Government of Maharashtra</td>
                                <td>As soon as it loses relevance</td>
                                <td>Automatically after the expiry of the validity period</td>

                            </tr>
                            <tr>
                                <td>10</td>
                                <td>BANNERS</td>
                                <td>As soon as it loses relevance</td>
                                <td>Automatically after the expiry of the validity period</td>

                            </tr>
                            <tr>
                                <td>11</td>
                                <td>RTI STATUS</td>
                                <td>As soon as it loses relevance</td>
                                <td>Automatically after the expiry of the validity period</td>

                            </tr>
                            <tr>
                                <td>12</td>
                                <td>TENDER/NOTICES</td>
                                <td>As soon as it loses relevance</td>
                                <td>Automatically after the expiry of the validity period</td>

                            </tr>
                            <tr>
                                <td>13</td>
                                <td>NEWS/ ADVERTISEMENTS</td>
                                <td>As soon as it loses relevance</td>
                                <td>Automatically after the expiry of the validity period</td>

                            </tr>
                            <tr>
                                <td>14</td>
                                <td>GROUP WISE CONTENTS</td>
                                <td>As soon as it loses relevance</td>
                                <td>Automatically after the expiry of the validity period</td>

                            </tr>
                            
                        </tbody>
                    </Table>
                </div>

            </div>
        </>
    )
}

export default index