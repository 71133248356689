import React from 'react'
import MinisterPhoto from '../ParentDepartment/MinisterPhoto'

const index = () => {
    return (
        <>
            <MinisterPhoto heading="Hyperlink Policy" />
            <div className='container mt-5'>
                <p>
                Links to External Websites/Portals At many places in this portal, you shall find links to other websites/portals created and maintained by other Government, non-Government / private organizations. These links have been placed for your convenience. When you select a link you are navigated to that website. Once on that website, you are subject to the privacy and security policies of the owners/sponsors of the website. Directorate Of Higher Education, Government Of Maharashtra, India is not responsible for the contents and reliability of the linked websites and does not necessarily endorse the views expressed in them. The mere presence of the link or its listing on this Portal should not be assumed as an endorsement of any kind. Links to the Directorate Of Higher Education, Government Of Maharashtra, India Website by Other Websites/Portals We do not object to you linking directly to the information that is hosted on our site, and no prior permission is required for the same. We do not permit our pages to be loaded into frames on your site. Our Department’s pages must load into a newly opened browser window of the user.
                </p>
            </div>
        </>
    )
}

export default index