import React, { useEffect, useState } from 'react';
import { useFormData } from '../../Contexts/FormDataContext';
import { SiMicrosoftexcel } from "react-icons/si";
import Form from 'react-bootstrap/Form';
import { FaUpload } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import formatFile from '../../Assets/Excel/Format Insurance.xlsx'
import { Button, Modal } from 'react-bootstrap';

function Upload() {
  const navigate = useNavigate()
  const errorFilePath = 'http://localhost/Yojana/swami_yojna/uploads1/excelError/'  // for saving  the path of the file 
  const { formData } = useFormData();
  const [selectedFileName, setSelectedFileName] = useState('');
  // const [error, setError] = useState('');
  const [numberOfStudents, setNumberOfStudents] = useState('') // for storing the number of students
  const [planName, setPlanName] = useState(''); // storing the name of the plan for eg. accident or mediclaim
  const [selectedCompany, setSelectedCompany] = useState(''); // for storing the name of the selected company
  const [enrollmentType, setEnrollmentType] = useState(''); // for storing the type of policy i.e. named or unnamed
  const [instId, setInstId] = useState(''); //  for storing the institute id
  const [excefile, setExcelFile] = useState(); // for storing the excel file
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [errorButton, setErrorButton] = useState(true)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [total, setTotal] = useState('')
  const [errorCount, setErrorCount] = useState('')
  const [messages, setMessage] = useState({})
  const [errorFile, setErrroFile] = useState('') /// for saving the error file 
  /////////// this is for opening the modal section /////
  const [lgShow, setLgShow] = useState(false);
  const [netAmount, setNetAmount] = useState()
  const [gst, setGst] = useState()
  const [totalPremium, setTotalPremium] = useState()
  const [isChecked, setChecked] = useState(false); // this is for checkbox part 

  const handleCheckboxChange = () => {
    setChecked(!isChecked);
  };

  const handleClose = () => setLgShow(false);
  const handleShow = () => setLgShow(true);

  useEffect(() => {
    // Enable the "Next" button if a valid excelfile is selected
    setIsNextButtonDisabled(!excefile || error);
  }, [excefile, error]);

  useEffect(() => {
    setInstId(sessionStorage.getItem('inst_id')) // storing the institute id 
  }, []); // Add an empty dependency array to run the effect only once

  function handleChange(event) {
    const file = event.target.files[0];
    setExcelFile(file)
    // Check if a file is selected
    if (file) {
      // Check if the selected file is an Excel file
      const isExcelFile = file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

      if (isExcelFile) {
        setSelectedFileName(file.name);
        setError('');
      } else {
        // Show an error if the selected file is not an Excel file
        setSelectedFileName('');
        setError('Please upload a valid Excel file.');
      }
    }
  }
  useEffect(() => {
    setEnrollmentType(formData.typeName)
    setPlanName(formData.planName)
    setSelectedCompany(formData.selectedCompany)
  }, [formData])

  function handleNavigate() {
    navigate('/review')

  }

  const handleDataSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Your logic for handling form submission, for example, using Axios
    const formData = new FormData();
    formData.append('id', instId);
    formData.append('plan_name', planName);
    formData.append('companyname', selectedCompany);
    formData.append('datatypename', enrollmentType);
    formData.append('excelname', excefile);
    formData.append('noofstud', numberOfStudents)

    axios.post('http://localhost/Yojana/swami_yojna/api/local/tempsubmit.php', formData)
      .then(response => {
        // Handle success, if needed
        console.log(response.data, "line 99")
        console.log(planName, "line 100")
        console.log(response.data.total_amt);
        setMessage(response.data)
        setNetAmount(response.data.Net_amt)
        setGst(response.data.GST)
        setTotalPremium(response.data.total_amt)

      })
      .catch(error => {
        // Handle error, if needed
        console.error(error);
      });
  };
  const handlefinalSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Your logic for handling form submission, for example, using Axios
    const formData = new FormData();
    formData.append('id', instId);
    formData.append('plan_name', planName);
    formData.append('companyname', selectedCompany);
    formData.append('datatypename', enrollmentType);
    formData.append('excelname', excefile);
    formData.append('noofstud', numberOfStudents)

    axios.post('http://localhost/Yojana/swami_yojna/api/local/finalsubmite.php', formData)
      .then(response => {
        // Handle success, if needed

        console.log(response.data, "line 126")
        console.log(response.data.total_amt);
        setMessage(response.data)
        // setNetAmount(response.data.Net_amt)
        // setGst(response.data.GST)
        // setTotalPremium(response.data.total_amt)

      })
      .catch(error => {
        // Handle error, if needed
        console.error(error);
      });
  };
  useEffect(() => {
    setErrorCount(messages.Error)
    setSuccess(messages.Successful)
    setTotal(messages.total_row)
    setErrroFile(messages.DuplicatedDataErrorFile)

  }, [messages])

  useEffect(() => {
    setErrorButton(errorCount > 0);
  }, [errorCount]);

  useEffect(() => {
    // Add an event listener for beforeunload
    const handleBeforeUnload = (event) => {
      // Cancel the event to prevent the default behavior
      event.preventDefault();
      // Standard message, works on most browsers
      event.returnValue = '';
    };

    // Attach the event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [excefile]);

  function downloadExcel() {
    window.open(formatFile)
  }
  function downloadError() {
    window.open(`${errorFilePath}${errorFile}`)
  }


  const termsAndConditionsText = `
    Your terms and conditions text goes here. 
    It can be quite lengthy to require scrolling. 
    Make sure to include all the necessary details.
    Your terms and conditions text goes here. 
    It can be quite lengthy to require scrolling. 
    Make sure to include all the necessary details.
    Your terms and conditions text goes here. 
    It can be quite lengthy to require scrolling. 
    Make sure to include all the necessary details.
    Your terms and conditions text goes here. 
    It can be quite lengthy to require scrolling. 
    Make sure to include all the necessary details.
    

    `.trim();
  return (
    <>
      <div className='container'>
        <div className='d-flex justify-content-center mt-5'>
          <div className='main-div row justify-content-md-center'>
            <div className='col-md-6 mb-2 heading-box mt-5 text-center'>
              <h4 className='heading-box-title mt-2'>Upload Data</h4>
            </div>
            <div className='col-md-2 mb-2 text-center format-button'>
              <button type='button' className='btn btn-warning' onClick={downloadExcel} >Format Of Excel</button>
            </div>
            <div className='container'>
              <div className='row'>
                <div className='ms-3 col-md-3'>
                  <div className='upload-box '>
                    <div className='d-flex flex-wrap upload-box-none justify-content-center p-3'>
                      <h4>Upload the data in excel format</h4>
                      <SiMicrosoftexcel className='uploads-icons' />
                    </div>
                  </div>
                </div>
                {
                  enrollmentType === "Named" ? (
                    <div className='col-md-8'>
                      <Form onSubmit={handleDataSubmit}>
                        <Form.Group controlId="formFileLg" className="mb-3">
                          <Form.Label><strong>Upload File</strong></Form.Label>
                          <Form.Control type="file" className='upload-details-form' onChange={handleChange} />
                        </Form.Group>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        {selectedFileName && <p style={{ color: 'grey' }}><strong>Selected File: {selectedFileName}</strong></p>}

                        <button className='mt-3 btn btn-warning'
                          onClick={() => setLgShow(true)}
                          disabled={isNextButtonDisabled}><FaUpload /> Upload</button>

                        {/* this is the code for modal  */}
                        <Modal size="lg"
                          show={lgShow}
                          onHide={() => setLgShow(false)}
                          aria-labelledby="example-modal-sizes-title-lg">
                          <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">Data Inserted :</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className='login-title text-center mt-3 d-flex justify-content-around p-2'>
                              <span>TotalCount : <strong>{total}</strong> </span>
                              <span>SuccessCount :<strong style={{ color: 'green' }}>{success}</strong> </span>
                              <span>Errors : <strong style={{ color: 'red' }}>{errorCount}</strong></span>
                              <span>
                                <button className='btn btn-warning ms-5'
                                  disabled={!errorButton}
                                  onClick={downloadError}><FaUpload /> Download Error file</button>
                              </span>
                            </div>
                            <div className='mt-4'>
                              <h6><strong>For {planName} your net per life premium is {netAmount} according to {selectedCompany}</strong></h6>
                              <h5><strong>Net Amount </strong>: {netAmount} Rupees</h5>
                              <h5><strong>GST</strong> : {gst} Rupees</h5>
                              <h5><strong>Total Premium</strong> : {totalPremium} Rupees</h5>
                            </div>
                            <div className='checkbox d-flex align-items-top'>
                              <form onSubmit={handlefinalSubmit}>
                                <div className='checkbox d-flex align-items-top'>
                                  <div className="form-check">
                                    <input className="form-check-input mt-4"
                                      type="checkbox"
                                      checked={isChecked}
                                      onChange={handleCheckboxChange}
                                      id="flexCheckDefault" />
                                    <label className="form-check-label" for="flexCheckDefault">
                                      <div className='scrollable-terms'>
                                        <div className='terms-text'>
                                          <p><strong>{termsAndConditionsText}</strong></p>
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                                <div className='d-flex justify-content-around mt-3 mb-3'>
                                  <button type='submit'
                                    disabled={!isChecked}
                                    className=' mt-3 btn btn-warning'>Submit</button>
                                </div>
                              </form>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                              Close
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </Form>
                      {/* /// this is the end of code  for modal  */}

                    </div>) : (
                    <>
                      <div className='col-md-8'>
                        <div className='text-center'>
                          <h4>You have selected un-named policy</h4>
                          <div className='feature-bottom'></div>
                        </div>
                        <div>
                          <Form onSubmit={handleDataSubmit}>
                            <Form.Group className="mb-3" controlId="formGroupEmail">
                              <Form.Label><strong>Enter the number of students</strong></Form.Label>
                              <Form.Control
                                type="text"
                                value={numberOfStudents}
                                onChange={e => setNumberOfStudents(e.target.value)}
                                placeholder="Enter number" />
                            </Form.Group>
                            <div className='d-flex justify-content-around mt-3 mb-3'>

                              <button disabled={errorButton} className=' mt-3 btn btn-warning' onClick={() => setLgShow(true)}>Submit</button>
                              <Modal size="lg"
                                show={lgShow}
                                onHide={() => setLgShow(false)}
                                aria-labelledby="example-modal-sizes-title-lg">
                                <Modal.Header closeButton>
                                  <Modal.Title id="example-custom-modal-styling-title">Data Inserted :</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                  <div className='mt-4'>
                                    <h5><strong>Net Amount </strong>: {netAmount} Rupees</h5>
                                    <h5><strong>GST</strong> : {gst} Rupees</h5>
                                    <h5><strong>Total Premium</strong> : {totalPremium} Rupees</h5>
                                  </div>
                                  <form onSubmit={handlefinalSubmit}>
                                    <div className='checkbox d-flex align-items-top'>
                                      <div className="form-check">
                                        <input className="form-check-input mt-4"
                                          type="checkbox"
                                          checked={isChecked}
                                          onChange={handleCheckboxChange}
                                          id="flexCheckDefault" />
                                        <label className="form-check-label" for="flexCheckDefault">
                                          <div className='scrollable-terms'>
                                            <div className='terms-text'>
                                              <p><strong>{termsAndConditionsText}</strong></p>
                                            </div>
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                    <div className='d-flex justify-content-around mt-3 mb-3'>
                                      <button
                                        disabled={!isChecked}
                                        className=' mt-3 btn btn-warning'>Submit</button>
                                    </div>
                                  </form>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button variant="secondary" onClick={handleClose}>
                                    Close
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </Form>
                        </div>

                      </div>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Upload;
