
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import { MdOutlineSupportAgent } from "react-icons/md";
const ContactInfo = () => {
    return (
        <>


            <div className="location-area mt-3  pb-70">
                <div className="container">
                    <div className="row justify-content-center location-wrap">
                        <div className="col-sm-6 col-lg-4">
                            <div className="location-item">
                                <i className="icofont-location-pin"><MdOutlineSupportAgent className="location-item-icon" /></i>
                                <h5><strong>Primary Helpline Number</strong></h5>
                                <p>
                                    +91 9649002216</p>
                                <br></br>
                                <h5><strong>Primary Email</strong></h5>
                                <p>
                                    info@buypolicynow.com</p>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-4">
                            <div className="location-item">
                                <i className="icofont-ui-message"><MdEmail className="location-item-icon" /></i>
                                <h5><strong>ICICI Lombard General Insurance Co. Ltd.</strong></h5>
                                <p>
                                    amrish.duddalwar@icicilombard.com</p>
                                <br></br>
                                <h5><strong>National Insurance Co. Ltd.</strong></h5>
                                <p>
                                    rahulb.patil@nic.co.in</p>
                                {/* <p>emailexample@name.com</p> */}
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-4">
                            <div className="location-item">
                                <i ><MdEmail className="location-item-icon" /></i>
                                <h5><strong>Integrated Risk Insurance Brokers Limited</strong></h5>
                                <p>
                                    {/* jigna.vora@buypolicynow.com */}
                                    info@svyuvasuraksha.org
                                </p>
                                {/* <p>+07 5554 3332 322</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactInfo;