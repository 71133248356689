import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import plans from '../../Assets/image/Plans.jpg'
import { Link } from 'react-router-dom';
import pa from '../../Assets/Excel/PA-SVYSY.pdf'
import mediclaim from '../../Assets/Excel/Mediclaim---SVYSY.pdf'

function MediclaimCoverage() {
    function documentOpen() {
        window.open(pa)
    }
    function documentOpen1() {
        window.open(mediclaim)
    }
    return (
        <>
            <div className='container mt-5'>
                {/* <div className="heading-home text-center mb-3">
                    
                </div> */}
                <div className='row mt-5'>

                    <div className='col-md-7'>
                        <div className='tabs-plans'>
                            <Tabs
                                defaultActiveKey="profile"
                                id="justify-tab-example"
                                className="mb-3"
                                justify
                            >
                                {/* <Tab eventKey="home"
                                    className='tab-color'
                                    title={<span style={{ fontWeight: 'bold', color: 'black', fontSize: '16px' }}>PERSONAL ACCIDENT</span>}>

                                    <ul style={{ textAlign: 'justify' }}>
                                        <li><strong>Accidental Death Benefit:</strong> The payment of Lumpsum amount to the nominee in the event of an accidental death of the beneficiary as described in the table above</li><br />
                                        <li><strong>Accidental Permanent Disability Benefit:</strong> The payment of Lumpsum amount to the nominee in the event of an accidental permanent disability of the beneficiary as described in the table above</li><br />
                                        <li><strong>Accidental Partial Disability Benefit:</strong> The payment of Lumpsum amount to the nominee in the event of an accidental partial disability of the beneficiary as described in the table above</li><br />
                                        <li><strong>Child Education Welfare / Children Education Grant:</strong> In the event of death or permanent total disablement of the Insured Parent/Guardian due to an Accident as defined, the Insurance Company shall pay ‘Child Education Grant’ for the Student enrolled in the policy, an additional compensation of a lumpsum pay out of 10% of the Capital Sum Insured once per event, per policy period</li><br />
                                        <li><strong>Accidental Hospitalisation Benefit:</strong> A cashless hospitalisation benefit across the network of empanelled hospitals (Pan-India) in case of bodily injuries to the primary insured member or the secondary insured member. The expenses towards the treatment during the hospitalization for up to a maximum of INR 50,000/- (Primary Plus Secondary insured) floater amount Per policy period. In case of the hospitalization event at the non-network/non-empanelled hospital, the insured can claim for the reimbursement of expenses from the Insurance Company towards the treatment undertaken at the hospitalisation due to accident. The policy shall cover only medicinal expenses including (not limited to): Room/ICU Charges, Doctor’s Fees, Cost of Investigation, Pharmacy Charges, Nursing Charges. All non-medical expenses, consumables shall not be payable under the policy</li>
                                    </ul>

                                    <div className='ms-3 '>
                                        <button onClick={documentOpen} className='btn btn-warning'>Read More</button>
                                    </div>
                                </Tab> */}
                                <Tab eventKey="profile"
                                    className='tab-color'
                                    title={<span style={{ fontWeight: 'bold', color: 'black', fontSize: '16px' }}>Mediclaim</span>}>
                                   
                                    <ul style={{ textAlign: 'justify' }}>
                                        <li><strong>Cover Type:</strong> Hospitalization Only</li><br />
                                        <li><strong>Admissibility:</strong> Cashless in Network Hospitals, Reimbursement in Non-Network Hospitals & Pre & Post Hospitalization Claims</li><br />
                                        <li><strong>In-patient Hospitalization for Covid_19:</strong> Covered</li><br />
                                        <li><strong>Pre-Existing Conditions:</strong> Covered from Day One</li><br />
                                        <li><strong>Waiver of 30 days, 1st Year Exclusion, 2nd Year Exclusion & 4th Year Exclusion</strong></li><br />
                                        <li><strong>Class of Treatment:</strong> Private Room (subject to Clause VII)</li><br />
                                        <li><strong>Room Rent Restriction:</strong> 2% of Sum Insured for Normal & 4% for ICU</li><br />
                                        <li><strong>Pre & Post Hospitalization:</strong> Medical Expenses arising 30 days Prior to Hospitalization & 60 days post hospitalization shall be payable</li><br />
                                        <li><strong>Ambulance Charges:</strong> INR 2000/- Per Hospitalization event shall be payable</li><br />
                                        
                                    </ul>
                                    <div className='ms-3'>
                                        <button onClick={documentOpen1} className='btn btn-warning'>Read More</button>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                    <div className='col-md-5'>
                        <div className='image-plans-box mt-5 ms-5'>
                            <img src={plans} className='image-plan' />
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default MediclaimCoverage