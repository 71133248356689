import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';

function CustomToggle({ children, eventKey, item  , onToggle}) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        axios.get(`http://localhost/Yojana/swami_yojna/api/local/getexceldata.php?excelname=${item}`)
            .then((response) => {
                // console.log(response.data)
                onToggle(response.data)
            })
    );

    return (
        <button
            type="button"
            className='btn btn-warning'
            // style={{ backgroundColor: 'pink' }}
            onClick={(item) => decoratedOnClick()}
        >
            {children}
        </button>
    );
}

function FinalDasborad() {
    const [reviewData, setReviewData] = useState([])
    const [excelData , setEcelData] = useState([])
    const inst_id = sessionStorage.getItem('inst_id')
    useEffect(() => {
        axios.get(`http://localhost/Yojana/swami_yojna/api/local/review.php?id=${inst_id}`)                                         /// insert the api here 
            .then((response) => {
                console.log(response.data)
                setReviewData(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [inst_id])
    return (
        <>
            <div className='container mt-5'>
                <Accordion>
                    {
                        reviewData.map((item) => (
                            <Card key={item.id}>
                                <Card.Header>
                                    <div className='d-flex gap-3 align-items-center'>
                                        <h6><strong>Insitiute name : </strong>{item.institute_name}</h6>
                                        <h6><strong>Product Name : </strong>{item.product_name}</h6>
                                        <h6><strong>Company name : </strong>{item.company_name}</h6>
                                        <h6><strong>Policy type : </strong>{item.type}</h6>
                                        <CustomToggle item={item.excel_name} onToggle={setEcelData} eventKey={item.id}>Excel File</CustomToggle>
                                    </div>

                                </Card.Header>
                                <Accordion.Collapse eventKey={item.id}>
                                    <Card.Body>
                                        <Table responsive striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th scope="col">StudentUID</th>
                                                    <th scope="col">FullName </th>
                                                    <th scope="col">Gender</th>
                                                    <th scope="col">DateOfBirth</th>
                                                    <th scope="col">MobileNo.</th>
                                                    <th scope="col">EmailD</th>
                                                    <th scope="col">AadhaarNo.</th>
                                                    <th scope="col">City</th>
                                                    <th scope="col">Pincode</th>
                                                    <th scope="col">GuardianName</th>
                                                    <th scope="col">GuardianGender</th>
                                                    <th scope="col">GuardianDOB</th>
                                                    <th scope="col">GuardianRelation</th>
                                                    <th scope="col">GuardianMobileNumber</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                               
                                                   {
                                                    excelData.map((item)=>(
                                                       <>
                                                        <tr>
                                                        <td scope='col'>{item.StudentUID}</td>
                                                        <td>{item.FullName}</td>
                                                        <td>{item.Gender}</td>
                                                        <td>{item.DOB}</td>
                                                        <td>{item.StudentMobileNumber}</td>
                                                        <td>{item.EmailD}</td>
                                                        <td>{item.StudentAadhaarNo}</td>
                                                        <td>{item.City}</td>
                                                        <td>{item.Pincode}</td>
                                                        <td>{item.GuardianName}</td>
                                                        <td>{item.GuardianGender}</td>
                                                        <td>{item.GuardianDOB}</td>
                                                        <td>{item.GuardianRelation}</td>
                                                        <td>{item.GuardianMobileNumber}</td>
                                                        </tr>
                                                       </>
                                                    ))
                                                   }
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        ))
                    }
                    {/* <Card>
                    <Card.Header>
                        <CustomToggle eventKey="1">Click me!</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>Hello! I'm another body</Card.Body>
                    </Accordion.Collapse>
                </Card> */}
                </Accordion>
            </div>
        </>
    )
}

export default FinalDasborad