import React, { useEffect, useState } from 'react';
import Slider from './Slider';

import Information from './Information';
import AboutUS from './Messages';


import AboutScheme from './AboutScheme';
import Navbar from '../../Constants/Navbar';
import Footer from '../Footer/Footer';


function Home() {

  const [visitCount, setVisitCount] = useState(0);

  // useEffect to update the visit count on component mount
  useEffect(() => {
    // Get the visit count from local storage
    const storedCount = localStorage.getItem('visitCount');

    // If visit count is stored, use it; otherwise, initialize to 0
    setVisitCount(storedCount ? parseInt(storedCount, 10) : 0);

    // Increment the visit count
    setVisitCount((prevCount) => prevCount + 1);
  }, []); // Empty dependency array ensures useEffect runs only once on mount

  // useEffect to save the visit count to local storage
  useEffect(() => {
    // Save the visit count to local storage
    localStorage.setItem('visitCount', visitCount.toString());
  }, [visitCount]);
  return (
    <>

      <Slider />
      {/* <Information /> */}
      {/* <AboutScheme /> */}
      <AboutUS />

    </>
  );
}

export default Home;
