import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AboutSchemeImage from '../../Assets/image/Viswas.jpg';

function YojanaLetter() {
  return (
    <div className="mt-5">
      <Container>
        <Row>
          <Col md={7}>
            <div className="text-justify" style={{ textAlign: 'justify' }}>
              <h2 style={{ fontWeight: '500' }}>
                <strong>Message from  Principal Secretary</strong>
              </h2>

              <div className='feature-bottom-1'></div>
              {/* <p>Dear Students, Parents, and Esteemed Colleagues,</p> */}
              <p className='mt-3'>
                It is with immense pleasure and a profound sense of responsibility that I extend my warm greetings to each one of you. As the Principal Secretary of the Government of Maharashtra, it is my privilege to be part of a transformative vision that places the well-being and aspirations of our youth at the forefront.
              </p>
              <p>
                The Swami Vivekananda Yuva Suraksha Yojana, spearheaded by the Hon. Minister of Higher & Technical Education, embodies the spirit of empowerment and foresight. Inspired by the teachings of Swami Vivekananda, this scheme is a testament to our commitment to creating an educational environment that not only imparts knowledge but also shields our students from unforeseen challenges.
              </p>
              <p>
                As someone deeply immersed in academia, I understand the pivotal role education plays in shaping the destiny of individuals and our society at large. It is heartening to witness the convergence of academic prowess and compassionate governance through initiatives like Swami Vivekananda Yuva Suraksha Yojana.
              </p>
              <p>
                My motivation aligns seamlessly with the shared vision for the youth of our nation – a vision that transcends academic excellence to encompass the holistic well-being of our students. I firmly believe that education should be a gateway to opportunity, unmarred by financial and familial hardships.
              </p>
              <p>
                In line with this belief, I urge each one of you to actively engage in the dissemination of Swami Vivekananda Yuva Suraksha Yojana. Let us make it a collective effort to ensure that every student in Maharashtra is not only aware of this protective shield but also feels the security it provides.
              </p>
              <p>
                This initiative is not just a scheme; it is an investment in the future leaders, thinkers, and innovators of our nation. Together, let's amplify the reach of Swami Vivekananda Yuva Suraksha Yojana and pave the way for a generation that thrives on the strength of its education and the unwavering support of its community.
              </p>
              {/* <p>
                For any inquiries or to contribute to this cause, please do not hesitate to reach out to us at [contact details]. Empowering our youth is not just a responsibility; it is our collective privilege.
              </p> */}


            </div>
          </Col>
          <Col md={5}>
            <div className='d-flex justify-content-center letter-yojana mt-5'>
              <img src={AboutSchemeImage} alt="About Scheme" className="img-swami" />
            </div>
            <div className='information-title-right  mt-3 ms-5 '>
              <h5 style={{ fontWeight: '400' }}><strong>Shri. Vikas Rastogi (IAS)</strong></h5>
              <h5 style={{ fontWeight: '400' }}><strong>Principal Secretary</strong></h5>
              <h5 style={{ fontWeight: '400' }}><strong> Higher & Technical Education</strong></h5>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default YojanaLetter;

{/* <p className="mt-0 mb-0"><strong>With Sincere Regards,</strong><br /><strong>Vikas Rastogi</strong><br /><strong>Hon. Principal Secretary</strong><br /><strong>Department of Higher & Technical Education</strong><br /><strong>Govt. of Maharashtra</strong></p> */}