import React from 'react'

const Press = () => {
  function openUrl(){
    window.open('https://theprint.in/ani-press-releases/maharashtras-new-student-insurance-program-aims-to-secure-futures/1810822/')
  }
  return (
    <>
      <div className="location-area pt-100 pb-70">
        <div className="container">
          <div className="row justify-content-center location-wrap">
            <div className="col-sm-6 col-lg-4">
              {/* <a href='https://theprint.in/ani-press-releases/maharashtras-new-student-insurance-program-aims-to-secure-futures/1810822/'> */}
                <div className="location-item" onClick={openUrl}>

                  <h4>
                    Swami Vivekananda Yuva Suraksha Yojana
                  </h4>
                  <p style={{color: "blue", fontSize:"15px"}}>
                    An Artical Regarding Our New Student Insurance Program
                  </p>
                </div>
              {/* </a> */}
            </div>

            {/* <div className="col-sm-6 col-lg-4">
              <div className="location-item">

              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="location-item">

              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Press