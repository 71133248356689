import React, { useState } from 'react'
import logo from '../../Assets/image/IntegratedlogoJpg.jpg'
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function UpdatePassword() {
    const loginApi = 'http://localhost/Yojana/swami_yojna/api/local/loginapi.php'
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate();
        const handleLogin =(e)=>{
            e.preventDefault();
            axios.post(loginApi,{
                username:username,
                password:password,
            })
            .then((response)=>{
                console.log(response.data)
               if(response.data.success===true){
                sessionStorage.setItem('token', response.data.token)
                sessionStorage.setItem('inst_id',response.data.id)
                Swal.fire({
                    icon: "success",
                    title: "You have been successfully logged IN",
                    showClass: {
                      popup: `
                        animate__animated
                        animate__fadeInUp
                        animate__faster
                      `
                    },
                    hideClass: {
                      popup: `
                        animate__animated
                        animate__fadeOutDown
                        animate__faster
                      `
                    }
                    
                  });
                  navigate("/")
               }
            })
            .catch((error)=>{
                console.log(error)
            })
        }
    return (
        <>
            <div className='container'>
                <div className='row d-flex justify-content-center '>
                    <div className='image-div d-flex justify-content-center mt-3'>
                        <img src={logo} className='image-login' />
                    </div>
                    <div className='login-main-div mt-4'>
                        <div className='login-title text-center mt-3'>
                            <h4 className='mt-1'>Forgot Password</h4>
                        </div>
                        <Form className='mt-3' onSubmit={handleLogin}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Control 
                                className='input-login'
                                 type="text" 
                                 value={username}
                                 onChange={e=>setUsername(e.target.value)}
                                 placeholder="Enter your username" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Control 
                                className='input-login' 
                                type="password" 
                                value={password}
                                onChange={e=>setPassword(e.target.value)}
                                placeholder="Enter your password" />
                            </Form.Group>
                            <div className='d-flex justify-content-center mb-3 mt-3'>
                                <button className='btn btn-warning text-center'>Submit</button>
                            </div>
                        </Form>
                        <div className='text-center'>
                            <p style={{ color: 'grey' }}><strong></strong><Link to='/login'><strong>Login</strong></Link></p>
                        </div>
                        <div className='text-center'>
                            <p style={{ color: 'grey' }}><strong>Don't have a account ?</strong><Link to='/sign-up'><strong>Sign-Up</strong></Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdatePassword