import React from 'react'
import Word from '../Home/Word'
import Agency from './Agency'
import MinisterPhoto from '../ParentDepartment/MinisterPhoto'
import Navbar from '../../Constants/Navbar'
import Footer from '../Footer/Footer'

function NodalAgency() {
  return (
    <>
 
      <MinisterPhoto heading="Integrated Risk Insurance Brokers Limited" />
      <Agency />
      <Word />

    </>
  )
}

export default NodalAgency