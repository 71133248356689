import React from 'react'
import NationalDoc from './NaitonalDoc'

function National() {
  return (
    <>
    <NationalDoc/>
    </>
  )
}

export default National