import React from 'react'
import MinisterPhoto from '../ParentDepartment/MinisterPhoto'

const index = () => {
    return (
        <>
            <MinisterPhoto heading="Contingency Management Plan" />
            <div className='container mt-5'>
                <div>
                    <h4>Contingency plan in the event of defacement / natural calamity</h4>
                    <p>
                        Clause 8.4 Directorate Of Higher Education, Government Of Maharashtra, India Department website has been placed in protected zones with the implementation of firewalls and IDS (Intrusion Detection System) and high availability solutions. Defacement Protection 1. Directorate Of Higher Education, Government Of Maharashtra, India website is audited for protection against Security & Performance degradation. 2. Any application level modification on the Directorate Of Higher Education, Government Of Maharashtra, India website requires re-audit. 3. All the server configuration and logs are monitored timely. 4. Only System administrator users are allowed to access the servers for doing administration and configuration tasks. 5. Except Front-end, all the backend servers are under lock and net secured. 6. Contents are updated through a secure CMS. There are two ways of monitoring of defacement of the Directorate Of Higher Education, Government Of Maharashtra, India website. The cybersecurity division monitors by analyzing the log files. In case of any eventuality whoever notices the defacement (either Website Monitoring Team or Cyber Security) informs the Web Information Manager on phone as well as through the mail. SDC Help Desk also informs the Administrator Public Work Department website on the telephone and also by mail.
                    </p>
                </div>
                <div className='mt-5'>
                    <h4>Contingency Plan in Case of Natural Calamity:</h4>
                    <p>There could be circumstances whereby due to some natural calamity (it may due to any reason that is beyond the control of any person); the entire data center where the Directorate Of Higher Education, Government Of Maharashtra, India website has been hosted gets destroyed or ceases to exist. In such case first of all the in-charge of Data Centre will declare the natural calamity and would instruct the sites to be started from other sites.</p>
                </div>
            </div>
        </>
    )
}

export default index