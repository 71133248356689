import React from 'react'
import MinisterPhoto from '../ParentDepartment/MinisterPhoto'

const index = () => {
    return (
        <>
            <MinisterPhoto heading="Content Contribution, Moderation & Approval (CMAP)" />
            <div className='container mt-5'>
                <p>
                    The Directorate Of Higher Education, Government Of Maharashtra, India has a mechanism stating the responsibility, authorization, and workflow details concerning content publishing on the site. The overall content of the website has been verified and checked thoroughly before publishing. The website also has a mechanism to ensure that the content has an appropriate authorization form within the Department before being published to the website. Audit trail of content entering, approval, and publishing of each content is being maintained showing who approved and when. The Website of the Directorate Of Higher Education, Government Of Maharashtra, India represents a single department where most content is contributed by a single set of sources. We hereby adopt a 2-tiered structure to implement CMAP requiring a minimum of 2 officials to execute the CMAP roles, viz. 1. Contributor 2. Moderator/Approver/Publisher **Note: Currently, an approved copy of the content is received by the publisher through email and the same is published on the website.
                </p>
            </div>
        </>
    )
}

export default index