import React from 'react'
import Plan from './Plan'

function Product() {
  return (
    <>
    <Plan/>
    </>
  )
}

export default Product