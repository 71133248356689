import React from 'react'
import MinisterPhoto from '../ParentDepartment/MinisterPhoto'

const index = () => {
  return (
   <>
   <MinisterPhoto heading="Website Policies" />
   <div className='container mt-5'>
                <ul>
                    <li>
                        <a href='/copyright-policy' className='text-reset  text-hover'>Copyright Policy</a>
                    </li>
                    <li>
                        <a href='/privacy-policy' className='text-reset  text-hover'>Privacy Policy</a>
                    </li>
                    <li>
                        <a href='/hyperlink-policy' className='text-reset  text-hover'>Hyperlink Policy</a>
                    </li>
                    <li>
                        <a href='/content-archival-policy' className='text-reset  text-hover'>Content Archival Policy</a>
                    </li>
                    <li>
                        <a href='/website-monitoring-plan' className='text-reset  text-hover'>Website Monitoring Plan</a>
                    </li>
                    <li>
                        <a href='/content-contribution' className='text-reset  text-hover'>Content Contribution, Moderation & Approval (CMAP)</a>
                    </li>
                    <li>
                        <a href='/content-review-policy' className='text-reset  text-hover'>Content Review Policy</a>
                    </li>
                    <li>
                        <a href='/contingency-management-plan' className='text-reset  text-hover'>Contingency Management Plan</a>
                    </li>
                    <li>
                        <a href='/security-policy' className='text-reset  text-hover'>Security Policy</a>
                    </li>
                </ul>
            </div>
   </>
  )
}

export default index