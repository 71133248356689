import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon, MDBRipple } from 'mdb-react-ui-kit';
import f1 from '../../Assets/image/f1.jpg'
import f2 from '../../Assets/image/f2.png'
import f3 from '../../Assets/image/f3.png'
import f4 from '../../Assets/image/f4.jpg'

export default function Footer() {
  const count = JSON.parse(localStorage.getItem('ip'))
  // console.log(count)

  return (
    <>
      <MDBFooter color='white' className='text-center text-lg-start text-muted Navbar-color mt-5'>
        <section className='pt-2'>
          <div className='text-center text-md-start mt-5 container'>
            <MDBRow className='mt-3'>
              {/* <MDBCol md="3" lg="4" xl="3" className='footer-color mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold h6-footer-logo'>
                <img src='/LOGO-01.svg' className='logo-img-footer' alt="Logo" />
              </h6>
              <p>
                Insurance policy is being provided to all the students studying in all the educational institutions under the Government of Maharashtra.
              </p>
            </MDBCol> */}



              <MDBCol md="4" className='mx-auto mb-4 footer-color'>
                <h4 className='text-uppercase fw-bold footer-heading mb-4'>Useful Links</h4>
                <p>
                  <a href='https://www.maharashtra.gov.in/' className='text-reset' target="_blank" rel="noopener noreferrer">
                    Government of Maharashtra
                  </a>
                </p>
                <p>
                  <a href='https://htedu.maharashtra.gov.in/Main/Default' className='text-reset' target="_blank" rel="noopener noreferrer">

                    Higher and Technical Education Department
                  </a>
                </p>
                <p>
                  <a href='https://dhepune.gov.in/' className='text-reset' target="_blank" rel="noopener noreferrer">
                    Directorate of Higher Education
                  </a>
                </p>
                <p>
                  <a href='https://dte.maharashtra.gov.in/' className='text-reset' target="_blank" rel="noopener noreferrer">
                    Directorate of Technical Education
                  </a>
                </p>
                <p>
                  <a href='https://htedu.maharashtra.gov.in/Main/DirectorateofArts' className='text-reset' target="_blank" rel="noopener noreferrer">
                    Directorate of Arts
                  </a>
                </p>
                <p>
                  <a href='https://msbte.org.in/' className='text-reset' target="_blank" rel="noopener noreferrer">
                    Maharashtra State Board of Technical Education
                  </a>
                </p>
              </MDBCol>

              {/* // second one  */}
              <MDBCol md="4" className='mx-auto mb-4 footer-color'>
                <h4 className='text-uppercase fw-bold footer-heading mb-4'>Information</h4>
                <p>
                  <a href='/accessibility-statement' className='text-reset' >
                    Accessibility Statement
                  </a>
                </p>
                <p>
                  <a href='/website-policies' className='text-reset' >
                    Website Policies
                  </a>
                </p>
                <p>
                  <a href='/terms-and-conditions' className='text-reset'>
                    Terms And Conditions
                  </a>
                </p>
                <p>
                  <a href='/disclaimer-footer' className='text-reset'>
                    Disclaimer
                  </a>
                </p>
              </MDBCol>


              <MDBCol md="4" xl="3" className='mx-auto mb-md-0 mb-4 footer-color '>
                <h4 className='text-uppercase fw-bold mb-4 footer-heading'>Contact</h4>
                <p>
                  <MDBIcon icon="envelope" className="me-3" />
                  <a href='mailto:info@buypolicynow.com' className='text-reset'>
                    info@svyuvasuraksha.org
                  </a>
                </p>
                {/* <p>
                  <MDBIcon icon="envelope" className="me-3" />
                  <a href='mailto:amrish.duddalwar@icicilombard.com' className='text-reset'>
                    amrish.duddalwar@icicilombard.com
                  </a>
                </p> */}
                {/* <p>
                  <MDBIcon icon="envelope" className="me-3" />
                  <a href='mailto:rahulb.patil@nic.co.in' className='text-reset'>
                    rahulb.patil@nic.co.in
                  </a>
                </p> */}
                <p>
                  <MDBIcon icon="phone" className="me-3" />
                  <a href='tel:+918411886135' className='text-reset'>
                    + 91 96490 02216
                  </a>
                </p>

              </MDBCol>
            </MDBRow>
          </div>
        </section>
      </MDBFooter>
      {/* <div className='underline'></div> */}
      <MDBFooter className='text-center  Navbar-color'>
        <MDBRow className='mt-3'>
          <MDBCol md="6" className=' p-3 ' style={{ color: 'white' }}>
            <strong>
              &copy; {new Date().getFullYear()} Copyright{' '}
              All Rights Reserved.
              Disclaimer
            </strong>
          </MDBCol>
          <MDBCol md="6" className=' p-3 ' style={{ color: 'white' }}>
            {/* <div className='d-flex'> */}
            <strong>VISIT COUNT:</strong>

            {/* <div className='background-count'> */}
            <strong className='background-count'>{count}</strong>
            {/* </div> */}
            {/* </div> */}

          </MDBCol>
        </MDBRow>
      </MDBFooter >
      <div className='underline'></div>
      <MDBFooter className='text-white Navbar-color'>
        <div className='p-3 container'>
          <section className=''>
            <MDBRow>
              <MDBCol lg='2' md='12' className='mb-4 mb-md-0'>
                <MDBRipple
                  rippleColor='light'
                  className='bg-image hover-overlay shadow-1-strong rounded'
                >
                  <img src={f1} className='w-100' />
                  <a href='https://idrc.ocadu.ca/checker/index.php?uri=referer&gid=WCAG2-AA'>
                    <div
                      className='mask'
                      style={{ backgroundColor: 'rgba(251, 251, 251, 0.2)' }}
                    ></div>
                  </a>
                </MDBRipple>
              </MDBCol>
              <MDBCol lg='2' md='12' className='mb-4 mb-md-0'>
                <MDBRipple
                  rippleColor='light'
                  className='bg-image hover-overlay shadow-1-strong rounded'
                >
                  <img src={f2} className='w-100' />
                  <a href='https://www.w3.org/WAI/WCAG2AA-Conformance'>
                    <div
                      className='mask'
                      style={{ backgroundColor: 'rgba(251, 251, 251, 0.2)' }}
                    ></div>
                  </a>
                </MDBRipple>
              </MDBCol>
              <MDBCol lg='2' md='12' className='mb-4 mb-md-0'>
                <MDBRipple
                  rippleColor='light'
                  className='bg-image hover-overlay shadow-1-strong rounded'
                >
                  <img src={f3} className='w-100' />
                  <a href='https://validator.w3.org/nu/?doc=https%3A%2F%2Fdhepune.gov.in%2F'>
                    <div
                      className='mask'
                      style={{ backgroundColor: 'rgba(251, 251, 251, 0.2)' }}
                    ></div>
                  </a>
                </MDBRipple>
              </MDBCol>
              <MDBCol lg='2' md='12' className='mb-4 mb-md-0'>
                <MDBRipple
                  rippleColor='light'
                  className='bg-image hover-overlay shadow-1-strong rounded'
                >
                  <img src={f4} className='w-100' />
                  <a href='https://jigsaw.w3.org/css-validator'>
                    <div
                      className='mask'
                      style={{ backgroundColor: 'rgba(251, 251, 251, 0.2)' }}
                    ></div>
                  </a>
                </MDBRipple>
              </MDBCol>

            </MDBRow>
          </section>
        </div>
      </MDBFooter>
    </>

  );
}
