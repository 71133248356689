import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FaQuoteLeft } from 'react-icons/fa';

function AboutUS() {
  const quotesData = [
    {
      icon: <FaQuoteLeft />,
      quote: "Arise, awake, and stop not until the goal is achieved.",
    },
    {
      icon: <FaQuoteLeft />,
      quote: "In a conflict between the heart and the brain, follow your heart.",
    },
    {
      icon: <FaQuoteLeft />,
      quote: "You cannot believe in God until you believe in yourself.",
    },
    {
      icon: <FaQuoteLeft />,
      quote: "The greatest religion is to be true to your own nature. Have faith in yourselves.",
    },
  ];

  return (
    <div className='background-container'>
      <div className="container mt-5 mb-5" style={{ paddingBottom: "20px" }}>
        <Row className='row-cols-1 row-cols-md-2 g-4 pt-5'>
          {quotesData.map((quote, index) => (
            <Col key={index}>
              <Card className='h-100 '>
                <Card.Body className='d-flex flex-column align-items-center justify-content-center'>
                  <Card.Text className='text-center'>
                    <strong>{quote.icon}</strong>
                  </Card.Text>
                  <Card.Text className='text-center'>
                    <strong>{quote.quote}</strong>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default AboutUS;
