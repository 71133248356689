import React from 'react'
import { Carousel } from 'antd';
import { Link } from 'react-router-dom';

function Slider() {
    return (
        <>
            <Carousel effect="fade" autoplay className='slider-comp mt-1 '>
                <div className='bg-1 banner d-flex align-items-center'>
                    <div className='container mt-5'>
                        <div className='row'>
                            <div className='col-6'>
                                <h3 className='banner-title mb-2'>"As long as we believe ourselves to be even the least different from God, fear remains with us; but when we know ourselves to be the One, fear goes; of what can we be afraid?"</h3>
                              
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg-2 banner  d-flex align-items-center '>
                    <div className='container mt-5'>
                        <div className='row'>
                            <div className='col-6'>
                                <h3 className='banner-title mb-6'>"Insurance policy is being provided to all the students studying in all the educational institutions under the Government of Maharashtra."</h3>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg-3 banner  d-flex align-items-center  '>
                    <div className='container mt-5'>
                        <div className='row'>
                            <div className='col-6'>
                                <h3 className='banner-title mb-2'>"The more we come out and do good to others, the more our hearts will be purified, and God will be in them."</h3>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel>
        </>
    )
}

export default Slider