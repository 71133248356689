import React from 'react'
import MinisterPhoto from '../ParentDepartment/MinisterPhoto'
import { FaLongArrowAltRight } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";

const index = () => {
    return (
        <>
            <MinisterPhoto heading="Accessibility Statement" />
            <div className='container information-para mt-5 ' style={{ textAlign: 'justify' }} >
                <p>We have put in our best efforts to ensure that all the information on this portal is accessible to people with disabilities.For example, a user with a visual disability can access this portal using assistive, such as screen readers and magnifiers.</p>
                <p>We also aim to be standards compliant and follow principles of usability and universal design, which should help all visitors to this portal. If you have any problem or suggestion regarding the accessibility of this portal, please contact us.</p>
                <p>Some scanned, complex pdf may be present on the website which sometimes unavailable for screen readers due to technical issues.</p>
                <div className='mt-5'>
                    <ul>
                        <li className='list-unstyled'>
                         <h4 className='mb-4'><FaLongArrowAltRight className='icon-arrow ' />Accessibility Features</h4>
                        </li>

                        <ul>
                            <li className='list-for-statement'><p>Skip to Main Content: Quick access to the core content on the page is provided without going through repetitive navigation using the keyboard.</p></li>
                            <li className='list-for-statement'><p>Skip to Main Content: Quick access to the navigation pane is provided that enables accessing the different sections, such as Citizens, Government, and Directories.</p></li>
                            <li className='list-for-statement'><p>Accessibility Options: Options to change the size of the text and set a color scheme are provided. For example, if you are using a desktop to access this portal, the text might appear smaller on the screen that makes it difficult to read. In such a situation, you can use this option to increase the size of the text for clear visibility and better readability.</p></li>
                            <li className='list-for-statement'><p>Descriptive Link Text: Brief description of a link is provided using descriptive phrases instead of just using words such as 'Read more' and 'Click here' as linked text. For example, if a link opens a PDF file, the description specifies its file size. Further, if a link opens a Web site in a new window, the description specifies the same.</p></li>
                            <li className='list-for-statement'><p>Table Headers: The table headings are marked and associated with their corresponding cells in each row. For example, if there are 30 rows and 5 columns in a table, it is difficult for a user with a visual disability to identify which data cell belongs to which header. In this situation, an assistive device such as a screen reader can read the column header of any cell for the user. Besides, captions are also specified for each table that acts as labels and indicates what data is provided in the table.</p></li>
                            <li className='list-for-statement'><p>Headings: The Web page content is organized using appropriate headings and subheadings that provide a readable structure. H1 indicates the main heading, whereas H2 indicates a subheading. Besides, for the screen reader users, there are hidden headings in this portal that are read out by a screen reader for better readability.</p></li>
                            <li className='list-for-statement'><p>Titles: An appropriate name for each Web page is specified that helps you to understand the page content easily.</p></li>
                            <li className='list-for-statement'><p>Alternate Text: Brief description of an image is provided for users with visual disabilities. If you are using a browser that supports only text or has turned off the image display, you can still know what the image is all about by reading the alternate text in absence of an image.</p></li>
                            <li className='list-for-statement'><p>Explicit Form Label Association: A label is linked to its respective control, such as text box, checkbox, radio button, and drop-down list. This enables the assistive devices to identify the labels for the controls on a form.</p></li>
                            <li className='list-for-statement'><p>Explicit Form Label Association: A label is linked to its respective control, such as text box, checkbox, radio button, and drop-down list. This enables the assistive devices to identify the labels for the controls on a form.</p></li>
                            <li className='list-for-statement'><p>Keyboard Support: The portal can be browsed using a keyboard by pressing the Tab and Shift + Tab keys.</p></li>
                            <li className='list-for-statement'><p>Customized Text Size: The size of the text on the Web page can be changed either through the browser or through the accessibility options feature.</p></li>
                            <li className='list-for-statement'><p>JavaScript Independent: Web page information and functionalities are independent of JavaScript, irrespective of the browser support for the scripting language.</p></li>
                        </ul>
                    </ul>
                </div>

            </div>
        </>
    )
}

export default index