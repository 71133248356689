import React from 'react'

function FirstComponent() {
  return (
    <div className='container mt-5'>
                <div className='row'>
                    {/* <div className='col-md-5 mt-5'>
                        <div className='minister-image d-flex justify-content-center align-items-center'>
                            <img src={minister} className='img-info' />
                        </div>
                        <div className='information-title mt-3'>
                            <h5 style={{ fontWeight: '400' }}><strong>Chintan Prakash Adva - MD & CEO,
                            </strong></h5>
                            <h5 style={{ fontWeight: '400' }}><strong> Integrated Risk Insurance Brokers Limited</strong></h5>
                        </div>
                    </div> */}
                    <div className='col-md-12 mt-3'>
                        <div className='minister-information'>
                            <div className='information-title'>
                                {/* <h2 style={{ fontWeight: '500' }}>Message from Hon’ble Minister</h2> */}
                                <h2 style={{ fontWeight: '500' }}><strong>About The Scheme</strong></h2>
                                <div className='feature-bottom-1'></div>
                            </div>
                            <div className='information-para mt-3 '>
                                <div style={{ textAlign: 'justify' }}>
                                    <p>
                                        The Directorate of Higher Education, Government of Maharashtra is proud to introduce a pioneering initiative. This forward-thinking insurance program aims to prioritize the well-being of students across the state studying under Higher & Technical education by providing a comprehensive insurance coverage tailored to their unique needs. Recognizing the diverse challenges that students may encounter during their educational journey, this scheme encompasses a range of benefits, including health coverage, accident insurance, and coverage for unforeseen circumstances. The comprehensive revamped Student Insurance Scheme titled Swami Vivekananda Yuva Suraksha Yojana reflects the dedication to supporting the holistic development of each student, ensuring they can pursue their education with confidence and peace of mind.
                                    </p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
  )
}

export default FirstComponent