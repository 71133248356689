import React from 'react'
import ContactInfo from './ContactInfo'
import ContactForm from './ContactForm'
import GoogleMap from './ContactDetails'
import ContactDetails from './ContactDetails'
import Enquiry from './Enquiry'


function Contacts() {
    return (
        <>
            <Enquiry />
            <ContactInfo />
            <ContactDetails />
            {/* <ContactForm/>  */}
            {/* <GoogleMap/> */}

        </>
    )
}

export default Contacts