import React from 'react'
import FirstComponent from '../PersonalAccidents/FirstComponent'
import MinisterPhoto from '../ParentDepartment/MinisterPhoto'
import MediclaimCoverage from './MediclaimCoverage'

function NationalBenefits() {
  return (
    <>
    <FirstComponent/>
    <MinisterPhoto heading="Coverage Benefits" />
    <MediclaimCoverage/>
    </>
  )
}

export default NationalBenefits