import React from 'react'
import UpdatePassword from './UpdatePassword'

function ForgotPassword() {
  return (
    <>
    <UpdatePassword/>
    </>
  )
}

export default ForgotPassword