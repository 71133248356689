import React from 'react'
import Director from './Director'
import HighEducation from './HighEducation'
import MinisterPhoto from '../ParentDepartment/MinisterPhoto'
import Navbar from '../../Constants/Navbar'
import Footer from '../Footer/Footer'


function ImplementingBody() {
  return (
    <>

      <MinisterPhoto heading="Directorate of Higher Education" />
      <Director />
      <HighEducation />


    </>
  )
}

export default ImplementingBody