import React from 'react'
import MinisterPhoto from '../ParentDepartment/MinisterPhoto'

const index = () => {
  return (
    <>
      <MinisterPhoto heading="Terms and Conditions" />
      <div className='container mt-5'>
        {/* <p>
          This official website of the “Directorate Of Higher Education, Government Of Maharashtra, India” has been developed to provide information to the general public. The documents and information displayed on this website are for reference purposes only and do not support is a legal document. Directorate Of Higher Education, Government Of Maharashtra, India does not warrant the accuracy or completeness of the information, text, graphics, links, or other items contained within the Website. As a result of updates and corrections, the web contents are subject to change without any notice from ” Directorate Of Higher Education, Government Of Maharashtra, India ” on the DHE website. In case of any variance between what has been stated and that contained in the relevant Act, Rules, Regulations, Policy Statements, etc, the latter shall prevail. Any specific advice or replies to queries in any part of the website are/are the personal views/opinion of such experts/consultants/persons and are not necessarily subscribed to by this Department or its websites.
        </p> */}

      </div>

    </>
  )
}

export default index