import React from 'react'
function    MinisterPhoto({heading}) {
    const orangishBackgroundColor = '#F5D5A2';
    return (
        <>
            <div className='mt-5' style={{ backgroundColor: orangishBackgroundColor, padding: '50px 0' }}>
                <div className='container'>
                    <div className='d-flex ms-3 justify-content-center minister-panel'>
                        <div>
                            <h1><strong>{heading}</strong></h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MinisterPhoto