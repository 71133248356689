import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import docNationalPdf from '../../Assets/Excel/National Claim Doc.pdf'

function Navigation() {
    const token = sessionStorage.getItem('token')
    function docNational() {
        window.open(docNationalPdf)
    }
    function handleLogout() {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('inst_id')

    }

    return (

        <>
            <Navbar sticky="top" data-bs-theme="dark" collapseOnSelect expand="lg" className="Navbar-color p-3" >
                <Container>
                    {/* <Navbar.Brand href="/">
                        <img src='/LOGO-01.svg' className='navbarImageSize' />
                    </Navbar.Brand> */}
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link href="/" active className='me-4 navigation-text active-link'><strong>Home</strong></Nav.Link>
                            <NavDropdown title={<span style={{ color: 'white', fontWeight: 'bold' }}>About Us</span>} className='me-2 navigation-text' id="basic-nav-dropdown">
                                {/* <NavDropdown.Item href='/minster-info'>
                                    Our Leadership
                                </NavDropdown.Item> */}
                                <NavDropdown.Item href='/about-scheme'>
                                    Parent Department
                                </NavDropdown.Item>
                                <NavDropdown.Item href='/implementing-body'>
                                    Implementing Body
                                </NavDropdown.Item>
                                <NavDropdown.Item href='/nodalAgency'>
                                    Nodal Agency
                                </NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title={<span style={{ color: 'white', fontWeight: 'bold' }}>Insurance Scheme</span>} className='me-2 navigation-text' id="basic-nav-dropdown">
                                <NavDropdown.Item href='/personal-accidents'>
                                    Personal Accident
                                </NavDropdown.Item>
                                <NavDropdown.Item href='/mediclaim-coverage'>
                                    Mediclaim
                                </NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title={<span style={{ color: 'white', fontWeight: 'bold' }}>Downloads</span>} className='me-2 navigation-text' id="basic-nav-dropdown">
                                <NavDropdown.Item href='/gr'>
                                    G.R'S
                                </NavDropdown.Item>
                                <NavDropdown.Item href='/national-document'>
                                    National Insurance Company
                                </NavDropdown.Item>
                                <NavDropdown.Item href='/icici-document'>
                                    ICICI Lombard GIC
                                </NavDropdown.Item>
                            </NavDropdown>
                            {/* <Nav.Link href="/coverage" active className='me-4 navigation-text active-link'><strong>Coverage Benefits</strong></Nav.Link> */}

                            {/* <Nav.Link href="/enrollment" className='me-4 navigation-text ' ><strong>Enrollment</strong></Nav.Link> */}
                            {/* <Nav.Link href="/about" className='me-4 navigation-text'><strong>About</strong></Nav.Link> */}
                            <Nav.Link href="/press" className='me-4 navigation-text'><strong>Press</strong></Nav.Link>
                            <Nav.Link href="/contact" className='me-4 navigation-text'><strong>Contact</strong></Nav.Link>



                            <Nav.Link href="https://app.svyuvasuraksha.org/login" className='me-4 navigation-text'><strong>Login</strong></Nav.Link>



                        </Nav>

                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Navigation