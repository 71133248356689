import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import plans from '../../Assets/image/Plans.jpg'
import { Link } from 'react-router-dom';
import pa from '../../Assets/Excel/PA-SVYSY.pdf'
import mediclaim from '../../Assets/Excel/Mediclaim---SVYSY.pdf'
import { Container, Row, Col, Table } from 'react-bootstrap';
const ContactDetails = () => {
  return (
    <>
      <div className="container">
        <div className='tabs-plans'>
          <Tabs
            defaultActiveKey="home"
            id="justify-tab-example"
            className="mb-3"
            justify
          >
            <Tab eventKey="home"
              className='tab-color'
              title={<span style={{ fontWeight: 'bold', color: 'black', fontSize: '16px' }}>ICICI Lombard Gic-Content Details</span>}>
              <Container>
                <Row className=''>
                  <Col>
                    <h4><strong>Communication Address & Contact Person at the Nodal Office of the Insurance Company</strong></h4>
                    <p>
                      Amrish Duddalwar <br />
                      Mobile Number: 8605007196 <br />
                      Email ID: Amrish.duddalwar@icicilombard.com
                    </p>
                  </Col>
                </Row>

                <Row className='mt-3'>
                  <Col>
                    <h4><strong>Phone Number & Email ID</strong></h4>
                    <p>
                      Mobile Number: 8605007196 <br />
                      Email ID: Amrish.duddalwar@icicilombard.com
                    </p>
                  </Col>
                </Row>

                <Row className='mt-3'>
                  <Col>
                    <h4><strong>Claim Coordinates</strong></h4>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Emp ID</th>
                          <th>Name</th>
                          <th>Mobile Number</th>
                          <th>Email ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Helpdesk</td>
                          <td>-</td>
                          <td>040 6843 2603</td>
                          <td>claimsupport@icicilombard.com</td>
                        </tr>
                        <tr>
                          <td>SPOC</td>
                          <td>Ashif Shaik</td>
                          <td>9763030523</td>
                          <td>ashif.shaik@icicilombard.com</td>
                        </tr>
                        <tr>
                          <td>Level 1</td>
                          <td>Praveen Goud</td>
                          <td>7799886819</td>
                          <td>praveen.goud@icicilombard.com</td>
                        </tr>
                        <tr>
                          <td>Level 2</td>
                          <td>Sabya Banerjee</td>
                          <td>8886062777</td>
                          <td>sabya.banerjee@icicilombard.com</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col>
                    <h4><strong><strong>GPA Claim</strong></strong></h4>
                    <Table striped bordered responsive>
                      <thead>
                        <tr>
                          <th>Emp ID</th>
                          <th>Name</th>
                          <th>Mobile Number</th>
                          <th>Email ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Helpdesk</td>
                          <td>-</td>
                          <td>040 6843 2603</td>
                          <td>claimsupport@icicilombard.com</td>
                        </tr>
                        <tr>
                          <td>SPOC</td>
                          <td>Bharath T</td>
                          <td>7304410671</td>
                          <td>bharath.nagaraja@icicilombard.com</td>
                        </tr>
                        <tr>
                          <td>Level 1</td>
                          <td>Sabya Banerjee</td>
                          <td>8886062777</td>
                          <td>sabya.banerjee@icicilombard.com</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col>
                    <h4><strong>NEFT/ RTGS Details</strong></h4>
                    <p>
                      <strong>NAME:</strong> ICICI LOMBARD GIC LTD <br />
                      <strong>Title of account in the bank:</strong> ICICI LOMBARD GIC LTD <br />
                      <strong>Account TYPE:</strong> Current Account <br />
                      <strong>Account No.:</strong> 000405007307 <br />
                      <strong>MICR Code:</strong> 400229002 <br />
                      <strong>IFSC CODE:</strong> ICIC0000004 <br />
                      <strong>Branch Address:</strong> Mumbai Nariman Point, free press house 215, Nariman, Mumbai-400021 <br />
                      <strong>PAN Card No:</strong> AAACI7904G
                    </p>
                  </Col>
                </Row>

                <Row className='mt-3'>
                  <Col>
                    <h4><strong>Communication Address for Cheque Drop-off</strong></h4>
                    <p>
                      Amrish Duddalwar <br />
                      5th Floor Landmark, Plot No 5 & 6 Ramdaspeth, Nagpur 440010, Maharashtra
                    </p>
                  </Col>
                </Row>
              </Container>

            </Tab>
            <Tab eventKey="profile"
              className='tab-color'
              title={<span style={{ fontWeight: 'bold', color: 'black', fontSize: '16px' }}>National Insurance Company-Contact Details</span>}>
              <div className="container">
                {/* <h2><strong>Swami Vivekanand Yuva Suraksha Yojana</strong></h2> */}

                <ol>
                  <li>
                    <h5><strong>Communication Address & Contact Person at Nodal Office of the Insurance Company</strong></h5>
                    <p>
                      National Insurance Company Ltd<br />
                      Pune Divisional Office-III<br />
                      1248-A, Asmani Plaza, Deccan Gymkhana, Pune-04<br />
                      <strong>नेशनल इंशुरन्स कम्पनी लि./ National Insurance Company Ltd</strong><br />
                      <strong>पुणे मंडल कार्यालय-III / Pune Divisional Office-III</strong><br />
                      <strong>१२४८-अ, अस्माणि प्लाझा, डेक्कन जिमखाना, पुणे.-०४ / 1248-A, Asmani Plaza, Deccan Gymkhana, Pune-04</strong><br />
                      <strong>राहुल बाळासाहेब पाटील / Rahul Balasaheb Patil</strong><br />
                      <strong>मंडल प्रबंधक / Divisional Manager</strong>
                    </p>
                  </li>

                  <li>
                    <h5><strong>Phone Number & Email ID</strong></h5>
                    <p>
                      Mobile: <strong>८४११८८६१३५ / 8411886135</strong><br />
                      Email ID: <strong>rahulb.patil@nic.co.in, Incharge.271500@nic.co.in, 271500@nic.co.in</strong>
                    </p>
                  </li>

                  <li>
                    <h5><strong>Claim Coordinates</strong></h5>
                    <p>
                      Intimation: <strong>Incharge.271500@nic.co.in, 271500@nic.co.in</strong><br />
                      Generic mails for the implementation of the scheme are in progress.<br />
                      Claim can be submitted at any National office in Maharashtra or anywhere in India. The list with office addresses and contact details is available <a href="https://nationalinsurance.nic.co.in/en/ouroffices" target="_blank" rel="noopener noreferrer">here</a>.
                    </p>
                  </li>


                  <li>
                    <h5><strong>NEFT/RTGS Details</strong></h5>
                    <p>
                      <strong>Name of the Bank: Kotak Mahindra Bank</strong><br />
                      <strong>Account Number: NATIONAL9927150000</strong><br />
                      <strong>IFSC: KKBK0000958</strong><br />
                      <strong>Type of Account: Current</strong><br />
                      <strong>Address: 1st Floor, Baktawar, No 229, Nariman Point, Mumbai. 400021</strong><br />
                      <strong>Swift Code: KKBKINBBCPC</strong>
                    </p>
                  </li>

                  <li>
                    <h5><strong>Communication Address for Cheque Drop-off</strong></h5>
                    <p>
                      Pune Divisional Office-III<br />
                      1248-A, Asmani Plaza, Deccan Gymkhana, Pune-04
                    </p>
                  </li>
                </ol>
              </div>
              {/* <div className='d-flex justify-content-end'>
                <button className='btn btn-warning'>Download</button>
              </div> */}
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default ContactDetails;