import React from 'react'
import InformationDetails from './InformationDetails'

function PanGstKyc() {
  return (
   <>
    <InformationDetails/>
   </>
  )
}

export default PanGstKyc