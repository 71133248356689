import React from 'react'
import MinisterPhoto from '../ParentDepartment/MinisterPhoto'

const index = () => {
  return (
   <>
     <MinisterPhoto heading="Privacy Policy" />
     <div className='container mt-5'>
        <p>
        Directorate Of Higher Education, Government Of Maharashtra, India website does not automatically capture any specific personal information from you, (like name, phone number, or e-mail address), that allows us to identify you individually. If the Directorate Of Higher Education, Government Of Maharashtra, India website requests you to provide personal information, you will be informed of the particular purposes for which the information is gathered and adequate security measures will be taken to protect your personal information. We do not sell or share any personally identifiable information volunteered on the Directorate Of Higher Education, Government Of Maharashtra, India website to any third party (public/private). Any information provided to this Directorate Of Higher Education, Government Of Maharashtra, India will be protected from loss, misuse, unauthorized access or disclosure, alteration, or destruction. We gather certain information about the User, such as Internet protocol (IP) addresses, domain name, browser type, operating system, the date and time of the visit, and the pages visited. We make no attempt to link these addresses with the identity of individuals visiting our site unless an attempt to damage the site has been detected.
        </p>
     </div>
   </>
  )
}

export default index