import React from 'react'
import MinisterPhoto from '../ParentDepartment/MinisterPhoto'

const index = () => {
  return (
    <>
      <MinisterPhoto heading="Copyright Policy" />
      <div className='container mt-5'>
        <p>Material featured on this portal may be reproduced free of charge in any format or media without requiring specific permission. This is subject to the material being reproduced accurately and not being used in a derogatory manner or a misleading context. Where the material is being published or issued to others, the source must be prominently acknowledged. However, the permission to reproduce this material does not extend to any material on this site which is identified as being the copyright of the third party. Authorization to reproduce such material is obtained from the copyright holders concerned.</p>
      </div>
    </>
  )
}

export default index  