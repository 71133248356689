import React from 'react'
import MinisterPhoto from './MinisterPhoto'
import YojanaLetter from './YojanaLetter'
import DeptStruct from './DeptStruct'
import History from './History'
import Navbar from '../../Constants/Navbar'
import Footer from '../Footer/Footer'

function AboutTheScheme() {
  return (
    <>

      <MinisterPhoto heading="Higher and Technical Education Department" />
      <History />
      <YojanaLetter />

      {/* <DeptStruct/> */}
    </>
  )
}

export default AboutTheScheme