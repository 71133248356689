import React from 'react'
import MinisterPhoto from '../ParentDepartment/MinisterPhoto'

const index = () => {
    return (
        <>
            <MinisterPhoto heading="Disclaimer" />
            <div className='container mt-5'>
                {/* <p>Every effort has been made to ensure the accuracy of data on this website. However, these need to be verified and supplemented with documents issued finally by the concerned authorities. National Informatics Centre/Directorate Of Higher Education, Government Of Maharashtra, India will not be responsible for any decision or claim that is based on displayed data. In no event will NIC/Directorate Of Higher Education, Government Of Maharashtra, India liable for any expense, loss or damage including, without limitation, indirect or consequential loss or damage, or any expense whatsoever arising from use, or loss of use, of data, arising out of or in connection with the use of this Portal. Links to other websites that have been included on this Portal are provided for public convenience only. NIC/Directorate Of Higher Education, Government Of Maharashtra, India not responsible for the contents or reliability of linked websites and does not necessarily endorse the view expressed within them. We cannot guarantee the availability of such linked pages at all times The content in all languages is simultaneously updated, irrespective of language constraint default languages are displayed where such content is not translated.</p> */}
            </div>
           
        </>

    )
}

export default index