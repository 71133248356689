import React from 'react'
import minister from '../../Assets/image/IntegratedlogoJpg.jpg'

function Agency() {
    return (
        <div className='container mt-5'>
            <div className='row'>
                <div className='col-md-5 mt-3'>
                    <div className='minister-image-1 d-flex justify-content-center align-items-center'>
                        <img src={minister} className='img-info-1' style={{ height: "180px" }} />
                    </div>
                    <div className='information-title mt-2'>
                        {/* <h5 style={{ fontWeight: '400'  }}><strong>Shri. Chandrakant Patil - Hon. Cabinet Minister </strong></h5> */}
                        {/* <h5 style={{ fontWeight: '400'  }}><strong> Higher & Technical Education, Govt. of Maharashtra</strong></h5> */}
                    </div>
                </div>
                <div className='col-md-7 mt-3'>
                    <div className='minister-information'>
                        <div className='information-title'>
                            {/* <h2 style={{ fontWeight: '500' }}>Message from Hon’ble Minister</h2> */}
                            <h2 style={{ fontWeight: '500' }}><strong>About the Nodal Agency</strong></h2>
                            <div className='feature-bottom-1'></div>
                        </div>
                        <div className='information-para mt-3 '>
                            <div style={{ textAlign: 'justify' }}>
                                <p>

                                Integrated Risk Insurance Brokers Limited, an IRDA Licensed Public Limited company has been duly appointed by Directorate of Higher Education, Government of Maharashtra to oversee the implementation, administration of the scheme, Swami Vivekananda Yuva Suraksha Yojana. The Nodal Agency shall work as an advisor, consultant, intermediary to the Directorate of Higher Education, Government of Maharashtra towards effective management of claims, services, enrolment, grievance management & uphold the interest of the Directorate of Higher Education for the efficient execution & fulfilment of the scheme

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Agency