import React, { useEffect } from 'react';
import Navbar from './Constants/Navbar';
import Router from './Router/Router';
import Footer from './Components/Footer/Footer';
import Dashboard from './Dashboard/main';
import { BrowserRouter } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';

function App() {
  // const [ip, setIp] = useState()
  useEffect(() => {
    axios.get('https://admin.svyuvasuraksha.org/getIpAddress.php')
      .then((res) => {
        console.log(res.data.totalVisitors);
        const totalVisitors = res.data.totalVisitors;
        // Stringify the totalVisitors before setting in localStorage
        localStorage.setItem("ip", JSON.stringify(totalVisitors));
      })
      .catch((error) => {
        console.error('Error fetching total visitors:', error);
      });
  }, []);

  return (
    <div>
      {/* Google Translate Widget Container */}


      {/* Your existing components */}
      <BrowserRouter>
        {/* <Navbar /> */}
        <Router />
        
        {/* <Footer /> */}
      </BrowserRouter>

      


    </div>
  );
}

export default App;
