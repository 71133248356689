import React from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { FaFileDownload } from 'react-icons/fa';
import GR from '../../Assets/Excel/vidyarthi vima Gr 28 11 2023.pdf'
import GRBenefits from '../../Assets/Excel/Vidyarthi Vima Gr 16 10 2023.pdf'


const GRDownload = () => {

    const downloadFile = (fileName) => {
        // Implement your file download logic here
        window.open(fileName)
      };

    return (
        <>
            <div className='container mt-5'>
                <Row xs={1} md={3} className='g-4'>
                    {/* Using xs={1} to make it a single column on small screens, and md={3} to have 3 columns on medium and larger screens */}
                    <Col>
                        <Card className='document' border='primary' style={{ boxShadow: '0 4px 8px #ec9c79' }}>
                            <Card.Body>
                                <FaFileDownload size={30} className='mb-3' />
                                <Card.Title>Vidyarthi Vima GR</Card.Title>
                                <Button
                                    variant='warning'
                                    onClick={() => downloadFile(GR)}
                                >
                                    Download
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='document' border='primary' style={{ boxShadow: '0 4px 8px #ec9c79' }}>
                            <Card.Body>
                                <FaFileDownload size={30} className='mb-3' />
                                <Card.Title>Vidyarthi Vima GR Benefits</Card.Title>
                                <Button
                                    variant='warning'
                                    onClick={() => downloadFile(GRBenefits)}
                                >
                                    Download
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default GRDownload